<div class="date-time-picker-container">
  <hf-date-picker
    [minDate]="minDate"
    [maxDate]="maxDate"
    [formControl]="datePickerInput"
    [datePickerLabel]="datePickerLabel"
    [error]="error"
    [required]="required"
    [placeholder]="placeholder"
    [readonly]="readonly"
    [reservedDates]="reservedDates"
    [disabledSelection]="disabledSelection"
  ></hf-date-picker>
  <hf-select [formControl]="timePickerInput" [placeholder]="timePickerLabel" [source]="timePickerValues" [showDisabledMessage]="false" [disabled]="disabledSelection"></hf-select>
</div>
