import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import * as Constants from 'honeyfield-component-library';
import { ImageRef, StrapiFile, UploadHelperService } from 'honeyfield-shared-library';
import { Subject, takeUntil } from 'rxjs';
import { AuthService } from '../../core/services';
import { SnackBarService } from '../../services/snack-bar.service';

@Component({
  selector: 'ned-upload-image',
  templateUrl: './upload-image.component.html',
  styleUrls: ['./upload-image.component.scss'],
})
export class UploadImageComponent implements OnInit {
  public img: StrapiFile;
  public uploadProgress: number;
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    public dialogRef: MatDialogRef<UploadImageComponent>,
    private snackBar: SnackBarService,
    private uploadHelperService: UploadHelperService
  ) {
    this.uploadHelperService.uploadProgess$.pipe(takeUntil(this.destroy$)).subscribe((progress) => {
      this.uploadProgress = progress;
    });
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    const user = AuthService.user;
    this.img = user?.image;
  }

  onDrop(event) {
    event.stopPropagation();
    event.preventDefault();
    this.uploadImage(event.dataTransfer.files, false);
  }

  onDragOver(event) {
    event.stopPropagation();
    event.preventDefault();
    this.uploadImage(event.dataTransfer.files, false);
  }

  close() {
    this.dialogRef.close();
  }

  uploadImage(fileList: FileList, closeModal = true) {
    const imageRef: ImageRef = {
      ref: Constants.userRef,
      refId: AuthService.user._id,
      field: Constants.fieldImage.toLocaleLowerCase(),
      source: Constants.userPermissionsSource,
    };

    this.uploadHelperService
      .uploadFileV2(fileList, imageRef)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (files) => {
          if (files && files.length > 0) {
            this.img = files[0];
            AuthService.user = { ...AuthService.user, image: files[0] };
          }
          if (closeModal) {
            this.close();
          }
        },
        (err) => {
          const title = 'ERRORS.ERROR';
          const message = 'ERRORS.UPLOAD_PROFILE';
          this.snackBar.presentToast(title, message, 'cancel', 'error');
        }
      );
  }
}
