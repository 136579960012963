import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { ToastBarComponent } from './toast-bar.component';
import { IconButtonModule } from '../icon-button/icon-button.module';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
    declarations: [ToastBarComponent],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatIconModule,
        IconButtonModule,
        TranslateModule
    ],
    exports: [ToastBarComponent]
})
export class ToastBarModule {
}
