import { registerLocaleData } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { filter, Subject, take, takeUntil } from 'rxjs';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { SEOService } from './services/seo.service';
import { FirebaseService } from './services/firebase.service';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from '../environments/environment';

@Component({
  selector: 'ned-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {

  private destroy$: Subject<void> = new Subject<void>();
  
  constructor(
    public translate: TranslateService,
    public _MatPaginatorIntl: MatPaginatorIntl,
    private seoService: SEOService,
    public router: Router,
    private firebaseService: FirebaseService) {
    const lang = this.translate.getBrowserLang();
    this.translate.setDefaultLang(lang);
    this.translate.use(lang);
    this.seoService.initialize();
    moment.locale(this.translate.currentLang);
    if (lang) {
      this.loadAngularLocale(lang).then((locale) => {
        registerLocaleData(locale.default);
      });
    }
  }

  ngOnInit() {

    if(Object.keys(environment.firebaseConfig).length > 0) {      
      this.firebaseService.initializeFirebase();

      this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.destroy$)
      )
      .subscribe((event: NavigationEnd) => {
        this.firebaseService.logNotificationEvent('route_changed', { url: event.url });
      });
    }

    this.translate
      .get('d')
      .pipe(take(1))
      .subscribe((t) => {
        this._MatPaginatorIntl.itemsPerPageLabel = this.translate.instant('PAGINATOR.ITEMS_PER_PAGE');
        this._MatPaginatorIntl.firstPageLabel = this.translate.instant('PAGINATOR.FIRST_PAGE');
        this._MatPaginatorIntl.lastPageLabel = this.translate.instant('PAGINATOR.LAST_PAGE');
        this._MatPaginatorIntl.nextPageLabel = this.translate.instant('PAGINATOR.NEXT_PAGE');
        this._MatPaginatorIntl.previousPageLabel = this.translate.instant('PAGINATOR.PREVIOUS_PAGE');
      });
  }

  private loadAngularLocale(language): any {
    switch (language.toLowerCase()) {
      case 'en':
        return import('@angular/common/locales/en');
      case 'de':
        return import('@angular/common/locales/de');
      default:
        return import('@angular/common/locales/en');
    }
  }
}
