<div class="category-groups" (clickOutside)="onCloseCategoryGroups()">
  <ng-container *ngFor="let group of categoryGroups; let first = first; let last = last">
    <span class="title">
      <div (click)="group.expanded = !group.expanded">
        <span>{{ 'CATEGORIES.' + group.name | translate }}&nbsp;({{ group.count }})</span>
        <mat-icon [inline]="true" *ngIf="group.expanded">keyboard_arrow_up</mat-icon>
        <mat-icon [inline]="true" *ngIf="!group.expanded">keyboard_arrow_down</mat-icon>
      </div>
      <hf-search
        *ngIf="first && showCategorySearch"
        [formControl]="searchText"
        [width]="'335px'"
        icon="search"
        [placeholder]="'GENERAL.SEARCH_CATEGORY' | translate"
        [addBorderRadius]="true"
      ></hf-search>
    </span>
    <ng-container *ngIf="group.expanded">
      <div class="categories" [class.last]="last">
        <ng-container *ngFor="let category of group.categories">
          <div class="category">
            <span
              *ngIf="group.categories.length > 1"
              class="category-name"
              (click)="isMobile ? (category.showSubCategories = !category.showSubCategories) : null"
            >
              {{ 'CATEGORIES.' + category.name | translate }}
              <mat-icon>{{category.showSubCategories ? 'arrow_drop_up' : 'arrow_drop_down'}}</mat-icon>
            </span>
            <ng-container *ngIf="category.showSubCategories">
              <div class="subcategories" [class.single-sub]="group.categories.length === 1">
                <div class="subcategory" *ngFor="let subCategory of category.rentable_item_sub_category">
                  <span
                    class="subcategory-name"
                    [class.active]="selectedSubcategory === subCategory._id"
                    (click)="toggleCategory(category, subCategory)"
                  >
                    <i class="icon-{{ subCategory.name.toLowerCase() }}"></i>
                    {{ 'CATEGORIES.' + subCategory.name | translate }}
                  </span>
                </div>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </ng-container>
    <div *ngIf="!last" class="line-break"></div>
  </ng-container>
</div>
