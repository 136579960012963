import { Component, OnInit } from '@angular/core';
import { filter } from 'rxjs';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { UtilsService } from 'honeyfield-component-library';

@Component({
  selector: 'ned-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.scss'],
})
export class MobileMenuComponent implements OnInit {
  public isProducts = false;
  public isProfile = false;
  public isMessage = false;

  constructor(
    private router: Router, 
    public utilService: UtilsService
    ) {}

  ngOnInit(): void {
    this.checkRoute();
  }

  checkRoute() {
    this.selectRoute(this.router.url);
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((route: NavigationEnd) => {
      this.selectRoute(route.url);
    });
  }

  selectRoute(url) {
    this.isProducts = url.includes('/products');
    this.isMessage = url.includes('/profile/messages');
    this.isProfile = url.includes('/profile') && !this.isMessage;
  }

  navigateToProducts() {
    this.router.navigateByUrl('products');
  }

  navigateToProfile() {
    this.router.navigateByUrl('profile');
  }

  navigateToMessages() {
    this.router.navigateByUrl('profile/messages');
  }
}
