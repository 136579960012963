import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from '../../services';

@Component({
  selector: 'ned-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  @Input() hide: boolean;

  public userLoggedIn: boolean;

  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    this.userLoggedIn = this.authService.isUserLoggedIn();
  }
}
