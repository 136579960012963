import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { StrapiFile } from 'honeyfield-component-library';

@Component({
  selector: 'ned-images-carousel',
  templateUrl: './images-carousel.component.html',
  styleUrls: ['./images-carousel.component.scss'],
})
export class ImagesCarouselComponent implements OnInit {
  @Input() productImages: Array<StrapiFile> = [];
  @Input() selectedImage: number;
  @Output() closeCarousel = new EventEmitter<void>();

  placeholder = 'assets/image-placeholder.png';
  placeholderNotAvailable = 'assets/image-placeholder-grayscale.png';

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    switch (event.key) {
      case 'ArrowLeft':
        this.previous();
        break;
      case 'ArrowRight':
        this.next();
        break;
      case 'Escape':
        this.close();
        break;
    }
  }

  public img: number;
  private otherImagesElement: HTMLElement;
  public borderStyle = '3px solid var(--brand-color)';

  constructor() {}

  ngOnInit(): void {
    this.img = this.selectedImage;
    this.otherImagesElement = document.querySelector('.other-images');
  }

  public close(): void {
    this.closeCarousel.emit();
  }

  public next(): void {
    this.selectedImage = this.selectedImage < this.productImages.length - 1 ? this.selectedImage + 1 : 0;
    this.scrollToOtherImage();
  }

  public previous(): void {
    this.selectedImage = this.selectedImage > 0 ? this.selectedImage - 1 : this.productImages.length - 1;
    this.scrollToOtherImage();
  }

  private scrollToOtherImage = (): void => {
    if (this.otherImagesElement !== null) {
      this.otherImagesElement.children.item(this.selectedImage).scrollIntoView({ behavior: 'smooth' });
    }
  };
}
