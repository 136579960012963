import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from '../services';
import { ConversionHelperService } from 'honeyfield-shared-library';
import { LoadingIndicationService } from '@needit-services';
import { SnackBarService } from '../../services/snack-bar.service';
import { environment } from '../../../environments/environment';
import { PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private request: HttpRequest<any>;
  private next: HttpHandler;
  public isServer: boolean;

  private errorPipe = catchError((err) => {
    const errMessage = Array.isArray(err.error?.message) ? err.error?.message[0] : err.error?.message;
    if (!this.isServer) {
      if (err.status === 401) {
        const title = 'ERRORS.ERROR';
        const message = err?.error?.message || 'ERRORS.401_UNAUTHORIZED';
        if (AuthService.token) {
          this.authService.refreshToken().subscribe((result) => {
            this.intercept(this.request, this.next).subscribe();
          });
        } else {
          this.toastService.presentToast(title, message, 'cancel', 'error');
        }
      } else {
        this.toastService.presentToast(errMessage, '', 'cancel', 'error');
      }
    }
    LoadingIndicationService.isLoading.next(false);
    return of([]);
  });

  constructor(
    @Inject(PLATFORM_ID) private platformId,
    @Inject(DOCUMENT) private document: Document,
    private authService: AuthService,
    private toastService: SnackBarService,
    private router: Router
  ) {
    this.isServer = !isPlatformBrowser(this.platformId);
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any> | any> {
    if (this.isServer) {
      const itemDetails = request.url.includes('rentable-items') && !request.url.includes('rentable-items/findAll');
      if (request.url.includes(environment.apiEndpoint) && !itemDetails) {
        this.next = next;
      }
    }

    let headers;

    headers = Object.assign({}, headers, { 'x-client-id': 'needit' });

    request = request.clone({
      setHeaders: headers,
    });

    if (!request.url.includes('refresh')) {
      this.request = request;
      this.next = next;
    }

    if (request.url.includes('account/login') || request.url.includes('account/register')) {
      return next.handle(request).pipe(this.errorPipe);
    }

    if (this.authService.isUserLoggedIn()) {
      const tokenExpTime = this.authService.checkTokenExpirationTime();
      //If token expiration time is less than 5 mins we need to refresh it
      if (tokenExpTime > 0 && tokenExpTime < 300 && !request.url.includes('refresh') && !AuthService.refreshing) {
        this.authService.refreshToken().subscribe();
      }

      //If token expiration time is expired navigate to login
      if (tokenExpTime < 0) {
        this.authService.navigateToLogout();
      }

      const useBearer = request.url.includes(environment.apiEndpointV2);

      if (request.url.includes('download')) {
        headers = Object.assign({}, headers, {
          Authorization: `${useBearer ? 'Bearer ' : ''}${AuthService.token}`,
          Accept: 'application/pdf',
        });
      } else if (!ConversionHelperService.getBoolean(request.headers.get('withoutAuth'))) {
        headers = Object.assign({}, headers, { Authorization: `${useBearer ? 'Bearer ' : ''}${AuthService.token}` });
      }

      if (!request.url.includes('refresh')) {
        this.request = request;
      }
    }

    request = request.clone({
      setHeaders: headers,
    });

    return next.handle(request).pipe(this.errorPipe);
  }
}
