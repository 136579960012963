import { Component, HostListener, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, Subject } from 'rxjs';
import { debounceTime, filter, takeUntil, tap } from 'rxjs/operators';
import { AccountFeaturesService } from 'honeyfield-shared-library';
import { Place, RentableItemCategory, RentableItemSubCategory } from '@shared-library-models/api-interfaces';
import { ProductsService } from '@needit-services';
import { UtilsService } from 'honeyfield-component-library';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { DateRange } from '@angular/material/datepicker';

@Component({
  selector: 'ned-products-filter',
  templateUrl: './products-filter.component.html',
  styleUrls: ['./products-filter.component.scss'],
})
export class ProductsFilterComponent implements OnInit {
  public isMobile = window.innerWidth <= 768;
  public dateFormat = 'YYYY-MM-DD';

  public today = moment().toDate();
  public startOfToday = moment().startOf('day').toDate();
  public endOfToday = moment().endOf('day').toDate();

  public Filter = {
    Location: 0,
    Date: 1,
    Category: 2
  }

  public filters = [
    { icon: 'location', name: 'GENERAL.ADD_LOCATION', position: this.Filter.Location },
    { icon: 'date', name: 'GENERAL.DATE_RANGE', position: this.Filter.Date },
    { icon: 'travel_bags', name: 'GENERAL.CATEGORY', position: this.Filter.Category },
  ];

  public showBreakLine = true;
  public showLowestPrice = true;
  public selectedFilter = null;

  public filterForm: UntypedFormGroup;
  public filteredPlaces: Observable<Place[]>;

  public selectedCategory = null;
  public selectedSubcategory = null;
  public selectedSubcategoryText = null;
  public selectedLocation = null;
  public selectedRange = null;
  public recentSearches = [];
  public dateLocale: string;

  public outsideClick: boolean;
  public searching: boolean;

  public filterRange: DateRange<Date> | undefined;

  public rentableCategories: RentableItemCategory[];
  public rentableSubCategories: RentableItemSubCategory[];

  private destroy$: Subject<boolean> = new Subject<boolean>();

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isMobile = window.innerWidth <= 768;
  }

  constructor(
    public translate: TranslateService,
    public fb: UntypedFormBuilder,
    public featuresService: AccountFeaturesService,
    private productService: ProductsService,
    private utilsService: UtilsService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    const recentPlaces = localStorage.getItem('recentPlaces');
    this.recentSearches = recentPlaces ? JSON.parse(recentPlaces) : [];
    this.isMobile = this.utilsService.isMobile;
    this.route.queryParams.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      this.mapFromQuery(params);
    });
    this.loadItemCategories();
  }

  ngOnInit(): void {
    this.dateLocale = this.translate.getBrowserLang();
    const params = this.route.snapshot.queryParams;
    this.filterRange = new DateRange<Date>(this.startOfToday, this.endOfToday);
    this.mapFromQuery(params);
    this.createForm();
    this.outsideClick = true;
  }

  mapFromQuery(params) {
    this.selectedCategory = params?.category;
    this.selectedSubcategory = params?.subCategory;
    this.selectedLocation = this.recentSearches.find((x) => x.lng === +params?.lng && x.lat === +params.lat);
    if (!this.selectedLocation) {
      if (params.location) {
        this.selectedLocation = {
          shortAddress: params.location,
          lng: params.lng,
          lat: params.lat,
        };
      }
    }
    if (params.from && params.to) {
      this.selectedRange = params;
    } else {
      const range = {
        from: moment(this.startOfToday).format(this.dateFormat),
        to: moment(this.endOfToday).format(this.dateFormat),
      };
      this.selectedRange = range;
      this.filterRange = undefined;
    }
    if (this.rentableCategories) {
      this.selectedSubcategoryText = this.findSubCategory(this.selectedSubcategory)?.name;
    }
  }

  public displayPlaceFn(place?: Place): string | undefined {
    return place ? place.fullAddress : undefined;
  }

  createForm() {
    this.filterForm = this.fb.group({
      location: [''],
      category: [null],
    });

    this.fc.location.valueChanges
      .pipe(
        filter((val) => typeof val === 'string'),
        tap(() => {
          this.searching = true;
        }),
        debounceTime(1000),
        takeUntil(this.destroy$)
      )
      .subscribe((val) => {
        this.filteredPlaces = this.featuresService.findPlaces(val).pipe(
          takeUntil(this.destroy$),
          tap(() => {
            this.searching = false;
          })
        );
      });
  }

  get fc() {
    return this.filterForm.controls;
  }

  clearFilters() {
    this.toggleFilter(null);
    this.createForm();
    this.selectedCategory = null;
    this.selectedSubcategory = null;
    this.selectedSubcategoryText = null;
    this.selectedLocation = null;
    this.selectedRange = null;
    this.filterRange = null;
    this.navigate({});
  }

  toggleFilter(value) {
    this.selectedFilter = this.selectedFilter !== value ? value : null;
  }

  selectPlace(value) {
    if (this.selectedLocation && this.selectedLocation.shortAddress === value.shortAddress) {
      this.selectedLocation = null;
      this.navigate({ location: null, lng: null, lat: null }, true);
      if (!this.isMobile) {
        this.toggleFilter(null);
      }
    } else {
      const index = this.recentSearches.findIndex((x) => x.shortAddress === value.shortAddress);
      if (index !== -1) {
        this.recentSearches.splice(index, 1);
      }
      if (this.recentSearches.length > 4) {
        this.recentSearches.splice(this.recentSearches.length - 1, 1);
      }
      this.recentSearches.unshift(value);
      localStorage.setItem('recentPlaces', JSON.stringify(this.recentSearches));
      this.selectedLocation = value;
      localStorage.setItem('searchedLocationObj', JSON.stringify(value));
      this.filteredPlaces = of([]);
      if (!this.isMobile) {
        this.navigate({ location: value.shortAddress, lng: value.lng, lat: value.lat }, true);
        this.toggleFilter(null);
      }
    }
  }

  loadItemCategories() {
    return this.productService.getRentableCategories().subscribe((categories) => {
      this.rentableCategories = categories;
      this.selectedSubcategoryText = this.findSubCategory(this.selectedSubcategory)?.name;
    });
  }

  selectedChange(m: any) {
    if (!this.filterRange?.start || this.filterRange?.end) {
      this.filterRange = new DateRange<Date>(m, null);
    } else {
      const start = this.filterRange.start;
      const end = m;
      if (end < start) {
        this.filterRange = new DateRange<Date>(end, start);
      } else {
        this.filterRange = new DateRange<Date>(start, end);
      }
    }
    const range = JSON.parse(JSON.stringify(this.filterRange));
    if (range.start && range.end) {
      const _params = {
        from: moment(range.start).format(this.dateFormat),
        to: moment(range.end).format(this.dateFormat),
      };
      this.selectedRange = _params;
      if (!this.isMobile) {
        this.toggleFilter(null);
        this.navigate(_params, true);
      }
    }
  }

  toggleCategory(c, sc) {
    if (this.selectedCategory !== c._id) {
      this.selectedCategory = c._id;
    }
    if (this.selectedSubcategory !== sc._id) {
      this.selectedSubcategory = sc._id;
      this.selectedSubcategoryText = sc.name;
    } else {
      this.selectedCategory = null;
      this.selectedSubcategory = null;
      this.selectedSubcategoryText = null;
    }
    if (!this.isMobile) {
      this.navigate({ subCategory: this.selectedSubcategory }, true);
      this.toggleFilter(null);
    }
  }

  onFilterApply() {
    switch (this.selectedFilter.position) {
      case 0:
        this.navigate(
          {
            location: this.selectedLocation.shortAddress,
            lng: this.selectedLocation.lng,
            lat: this.selectedLocation.lat,
          },
          true
        );
        break;
      case 1:
        const range = JSON.parse(JSON.stringify(this.filterRange));
        const _params = {
          from: moment(range.start).format(this.dateFormat),
          to: moment(range.end).format(this.dateFormat),
        };
        this.selectedFilter = _params;
        this.navigate(_params, true);
        break;
      case 2:
        this.navigate({ subCategory: this.selectedSubcategory }, true);
        break;
      default:
        break;
    }
    this.toggleFilter(null);
  }

  onFilterClear() {
    switch (this.selectedFilter.position) {
      case 0:
        this.selectedLocation = null;
        this.navigate({ lng: null, lat: null }, true);
        break;
      case 1:
        this.filterRange = new DateRange<Date>(null, null);
        this.navigate({ from: null, to: null }, true);
        break;
      case 2:
        this.selectedSubcategory = null;
        this.selectedSubcategoryText = null;
        this.navigate({ subCategory: null }, true);
        break;
      default:
        break;
    }
    this.toggleFilter(null);
  }

  navigate(params, merge?) {
    const queryParams = this.route.snapshot.queryParams;
    if (queryParams.page && merge) {
      params = Object.assign({}, params, { page: 1, itemsPerPage: queryParams.itemsPerPage });
    }

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: params,
      queryParamsHandling: merge ? 'merge' : '',
    });
  }

  closeCategories() {
    if(this.selectedFilter.position === this.Filter.Category){   
        this.toggleFilter(this.selectedFilter)
    }
  }

  onCategoryChanged(category) {
    let findCategory = null;
    if (category) {
      findCategory = this.findSubCategory(category._id);
    } else {
      findCategory = null;
    }
    this.selectedSubcategory = findCategory?._id ?? null;
    this.selectedSubcategoryText = findCategory?.name ?? null;
  }

  findSubCategory(id: string): RentableItemSubCategory {
    if (id) {
      let subCategory = null;
      this.rentableCategories.forEach((c) => {
        c.rentable_item_sub_category.forEach((sc) => {
          if (sc._id === id) {
            subCategory = { _id: sc._id, name: sc.name };
          }
        });
      });
      return subCategory;
    } else {
      return null;
    }
  }
}
