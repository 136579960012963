import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmationDialogComponent } from './confirmation-dialog.component';
import { TextButtonModule } from '../text-button/text-button.module';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ColorModule } from '../colors/color.module';


@NgModule({
    declarations: [ConfirmationDialogComponent],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        TranslateModule,
        ColorModule,
        MatDialogModule,
        TextButtonModule
    ],
    exports: [ConfirmationDialogComponent]
})
export class ConfirmationDialogModule {
}
