export enum HfMenuItemCategory {
  ROOMS = 'Rooms',
  RENTALS = 'Rentals',
  OTHER = 'Other',
}

export interface HFMenuItem {
  id?: number;
  label?: string;
  iconLeft?: string;
  iconRight?: string;
  rightIconUrl?: string;
  url?: string;
  openDialog?: DialogProps;
  iconPath?: string;
  disabled?: boolean;
  module?: string;
  count?: number;
  category?: HfMenuItemCategory;
  activeTab?: number;
  target?: boolean;
  subitems?: HFMenuSubItem[];
  submenuCollapsed?: boolean;
  locked?: boolean;
}

export interface HFMenuSubItem {
  id?: number;
  label?: string;
  url?: string;
  href?: string;
  openDialog?: DialogProps;
  disabled?: boolean;
  module?: string;
  count?: number;
  category?: HfMenuItemCategory;
  activeTab?: number;
  target?: boolean;
  selected?: boolean;
  action?: string;
  locked?: boolean;
}

export interface DialogProps {
  shouldOpenDialog: boolean;
  params?: any;
}
