import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { finalize, Observable } from 'rxjs';
import { LoadingIndicationService } from '@needit-services';

@Injectable()
export class HttpsRequestInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!req.headers.get('hideinterceptor')) {
      LoadingIndicationService.isLoading.next(true);
    }
    return next.handle(req).pipe(finalize(() => LoadingIndicationService.isLoading.next(false)));
  }
}
