import { BusinessLocation } from '@soda-models/business-location';

export enum OptionType {
  Booking = 'Booking',
  Rental = 'Rental',
  Both = 'Both',
}

export interface Pagination {
  limit?: number;
  start?: number;
}

export enum BusinessTypeEnum {
  Rental = 'Rental',
  Agency = 'Agency',
}

export enum PaymentMethod {
  Cash = 'Cash',
  Card = 'Card',
  BankTransfer = 'Bank transfer',
  Other = 'Other',
}

export interface OnboardingSetting {
  businessType: BusinessTypeEnum;
  businessName: string;
  rentalType: OptionType;
  address: any;
}

export interface ImageRef {
  ref: string;
  refId: string;
  field: string;
  source?: string;
}

export interface Region {
  name: string;
}

export interface Country {
  code: string;
  name: string;
  flag: string;
  regions: Region[];
  suggested?: boolean;
}

export interface Guest {
  _id?: string;
  Firstname?: string;
  Lastname?: string;
  createdAt?: string;
  Notes?: string;
  Country?: string | Country;
  City?: string | Region;
  Zip?: string;
  Street?: string;
  StreetNumber?: string;
  AdditionalInfo?: string;
  email?: string;
  phone?: string;
}

export enum PaidStatusEnum {
  Paid = 'Paid',
  Unpaid = 'Unpaid',
  Downpayment = 'Downpayment',
}

export interface Channel {
  _id?: string;
  Name: string;
  Provision: number;
  ProvisionType: 'Fix' | 'Procentual';
  Color: string;
  Default: boolean;
}

export interface Option {
  _id?: string;
  Name: string;
  Price: number;
  Description: string;
  OptionType: string;
  AvailabilityStatus?: boolean;
}

export interface Room {
  _id?: string;
  Name: string;
  Persons: number;
  Children: number;
  Price: number;
  Color: string;
  Description?: string;
  AvailabilityStatus?: boolean;
  reserved?: boolean;
  reservations?: Reservation[];
  images: StrapiFile[];
  WebsiteLink: string;
  minDuration?: number;
  Category?: number;
  price_schema?: Pricing;
  isAvailable?: boolean;
  features?: string[];
  customProperties?: KeyValuePair[];
}

export interface Reservation {
  _id?: string;
  guest?: Guest;
  Persons?: number;
  Children?: number;
  Price?: number;
  netPrice?: number;
  totalPrice?: number;
  discount?: number;
  room?: Room;
  arrival?: string;
  departure?: string;
  arrivalDate?: string;
  departureDate?: string;
  checkIn?: string;
  checkOut?: string;
  Notes?: string;
  payed: PaidStatusEnum;
  payedAmount?: number;
  booking_channel?: Channel;
  Rentable_item?: RentableItem;
  hovering?: boolean;
  vat?: number;
  taxRate?: number;
  option_mappings?: BookingOption[];
  optionsNetPrice?: number;
  paymentMethod?: string;
  partnerRequestId?: string;
  paidFromStripe?: boolean;
  status?: ReservationStatus;
}

export interface DayRow {
  day: number;
  week: string;
  month: number;
}

export interface BookingOption {
  _id?: string;
  Quantity?: number;
  Option?: Option;
  Reservation?: Reservation;
  Rental?: Rental;
  OptionName?: string;
  OptionPrice?: number;
}

export interface Rental {
  _id?: string;
  id?: string;
  start?: string;
  end?: string;
  startDate?: string;
  startTime?: string;
  endDate?: string;
  endTime?: string;
  guest?: Guest;
  rentable_item?: RentableItem;
  discount?: number;
  discountPrice?: number;
  netPrice?: number;
  totalPrice?: number;
  notes?: string;
  booking_channel?: Channel;
  payed: PaidStatusEnum;
  payedAmount?: number;
  price?: number;
  hovering?: boolean;
  vat?: number;
  taxRate?: number;
  option_mappings?: BookingOption[];
  optionsNetPrice?: number;
  paymentMethod?: string;
  partnerRequestId?: string;
  isAvailable?: boolean;
  features?: string[];
  customProperties?: KeyValuePair[];
  paidFromStripe?: boolean;
  status?: ReservationStatus;
  isFixedPrice?: boolean;
  isDiscountTypeFix?: boolean;
  overridePrice?: boolean;
  guestFullName?: string;
  pricePerDay?: number;
  pricePerHour?: number;
  rentalNumber?: string;
}

export interface RentalDetails {
  rental: Rental[];
  netPrice?: number;
  totalPrice?: number;
  vat?: number;
  discount?: number;
  payedAmount?: number;
  payed: PaidStatusEnum;
  paymentMethod?: string;
}

export interface RentalResponse {
  data: Rental[];
  total?: number;
  page?: number;
  itemsPerPage?: number;
}

export interface RentableItem {
  _id?: string;
  Name: string;
  Price: number;
  Color: string;
  Description: string;
  AvailabilityStatus?: boolean;
  Deposit: number;
  reservations?: any[];
  reserved?: boolean;
  images: StrapiFile[];
  WebsiteLink: string;
  minDuration?: number;
  priceSchema?: Pricing;
  price_schema?: Pricing;
  isAvailable?: boolean;
  features?: string[];
  customProperties?: KeyValuePair[];
  rentable_item_category?: RentableItemCategory;
  rentable_item_sub_category?: RentableItemSubCategory;
  user?: any;
  account_setting?: any;
  distance?: number;
  accountLogoUrl?: string;
  location?: string;
  business_location?: BusinessLocation;
}

export interface RentableItemsData {
  data?: RentableItem[];
  total?: number;
}

export interface RentableItemCategory {
  _id?: string;
  name: string;
  rentable_item_sub_category: RentableItemSubCategory[];
  showSubCategories: boolean;
}

export interface RentableItemSubCategory {
  _id?: string;
  name: string;
  rentable_item_category: RentableItemCategory;
}

export interface UploadFileData {
  maximumSize: number;
  allowedFormats: string[];
  dimensions?: string;
}

export interface Info {
  Image?: StrapiFile;
  Header?: string;
  Street?: string;
  City?: string;
  Zip?: string;
  Country?: string;
  Email?: string;
  Homepage?: string;
  Phone?: string;
  VatID?: string;
  AccountOwner?: string;
  IBAN?: string;
  BIC?: string;
  TaxRate?: number;
  AutoSendConfirmation?: boolean;
  bookableDays?: Array<string>;
  StartOpeningHours: string;
  EndOpeningHours: string;
  AllowBookingOutsideBusinessHours?: boolean;
  CheckInHours: string;
  CheckOutHours: string;
  VatActivated?: boolean;
  RoomsVatID?: string;
  RoomsTaxRate?: number;
  RoomsVatActivated?: boolean;
  ItemsVatID?: string;
  ItemsTaxRate?: number;
  ItemsVatActivated?: boolean;
  EmailFooter?: string;
  Location?: Location;
}

export interface AccountFeatures extends Info {
  _id?: string;
  partners?: boolean;
  rooms?: boolean;
  channels?: boolean;
  items?: boolean;
  options?: boolean;
  tables?: boolean;
  accountType?: 'free' | 'premium' | 'custom';
  OnboardingStatus?: 'uncompleted' | 'completed' | 'inprogress' | 'skipped';
  PublicProfileEnabled?: boolean;
  user: { _id?: string; id?: string; firstname?: string; lastname?: string; stripeAccountId?: string; public_profile_setting?: PublicProfileSettings; };
  createdAt?: string;
}

export interface Place {
  shortAddress?: string;
  fullAddress?: string;
  lng?: number;
  lat?: number;
}

export interface ImageRef {
  ref: string;
  refId: string;
  field: string;
  source?: string;
}

export interface StrapiFile {
  createdAt: Date;
  ext: string;
  formats: { thumbnail: { name: string; url: string } };
  hash: string;
  height: number;
  id: string;
  mime: string;
  name: string;
  provider: string;
  provider_metadata: {
    public_id: string;
    resource_type: string;
  };
  related: any[];
  size: number;
  updatedAt: Date;
  url: string;
  width: number;
  __v: number;
  _id: string;
}

export interface UserStripeInformation {
  createdAt?: Date;
  customer_created?: Date;
  customer_id: string;
  default_source: string;
  id: string;
  _id: string;
  price_id: string;
  published_at?: Date;
  subscription_id: string;
  updatedAt?: Date;
  user: string;
}

export interface StripeRecurring {
  aggregate_usage: string;
  interval: string;
  interval_count: string;
  trial_period_days: string;
  usage_type: string;
}

export interface StripeCard {
  brand: string;
  country: string;
  exp_month: number;
  exp_year: number;
  fingerprint: string;
  funding: string;
  last4: string;
  address_city?: string;
  address_country?: string;
  address_line1?: string;
  address_line1_check?: string;
  address_line2?: string;
  address_state?: string;
  address_zip?: string;
  address_zip_check?: string;
  customer?: string;
}

export interface StripeCharge {
  amount: number;
  source?: StripeCard;
}

export interface StripeChargesObject {
  data: StripeCharge[];
}

export interface StripePaymentIntent {
  charges: StripeChargesObject;
}

export interface StripePlan {
  id: string;
  active: boolean;
  amount: number;
  created: number;
  currency: string;
  interval: string;
  interval_count: number;
  metadata: any;
  usage_type: string;
  product?: StripeProduct;
}

export interface StripeProduct {
  id: string;
  active: boolean;
  attributes: [];
  created: number;
  description: string;
  images: [];
  metadata: any;
  name: string;
  object: string;
  type: string;
  updated: number;
  url: string;
  recurring: StripeRecurring;
  price: number;
  planId: string;
}

export interface StripeSubscription {
  id: string;
  collection_method: string;
  created: number;
  current_period_end: number;
  current_period_start: number;
  customer: string;
  default_payment_method?: string;
  default_source?: string;
  metadata?: any;
  plan: StripePlan;
  quantity: number;
  start_date: number;
  status: string;
}

export interface StripeInvoice {
  id: string;
  account_country: string;
  account_name: string;
  amount_due: number;
  amount_paid: number;
  amount_remaining: number;
  billing_reason: string;
  charge: string;
  collection_method: string;
  created: number;
  currency: string;
  customer: string;
  customer_email: string;
  customer_address?: string;
  hosted_invoice_url: string;
  invoice_pdf: string;
  object: string;
  paid: boolean;
  period_end: number;
  period_start;
  status: string;
  subscription?: StripeSubscription;
  subtotal: number;
  tax?: number;
  total: number;
  payment_intent?: StripePaymentIntent;
  cardInformation?: StripeCard;
  totalConverted?: number;
  currentProduct?: string;
}

export interface UserDto {
  user: User;
  token: string;
  refreshToken: string;
}

export interface User {
  id?: string;
  _id: string;
  email: string;
  username?: string;
  createdAt?: Date;
  image?: StrapiFile;
  firstname: string;
  lastname: string;
  confirmed?: boolean;
  blocked?: boolean;
  fullName?: string;
  user_stripe_information?: UserStripeInformation;
  stripeAccountId?: string;
  role?: Role;
  phone?: string;
  PasswordChangeRequired?: boolean;
  public_profile_setting?: PublicProfileSettings;
}

export interface PublicProfileSettings {
  id?: string;
  _id?: string;
  aboutSectionDescription?: string;
  aboutSectionImage?: StrapiFile[];
  heroImage: StrapiFile[];
  heroSectionDescription?: string;
  createdAt?: Date;
  isAboutSectionEnabled?: boolean;
  isRoundedButtons?: boolean;
  primaryColor: string;
  secondaryColor?: string;
  updatedAt?: Date;
  user?: string;
  navigationLogo?: StrapiFile;
}

export interface Role {
  id: string;
  description: string;
  name: string;
  role_limitation: string;
  type: string;
  updatedAt?: Date;
}

export enum RoleName {
  Starter = 'Starter',
  Professional = 'Pro',
  Enterprise = 'Enterprise',
}

export interface RoleLimitation {
  _id?: string;
  RoomsQty: number;
  ItemsQty: number;
  UsersQty: number;
  OptionsQty: number;
  PriceQty?: number;
  PriceVariationsQty?: number;
  role: Role;
  LocationsQty: number;
  CmsEnabled?: boolean;
  CustomIntegrationsEnabled?: boolean;
  CustomPropertiesEnabled?: boolean;
  ErpEnabled?: boolean;
  InvoicesEnabled?: boolean;
  ManageBookingAddonsEnabled?: boolean;
  MultipleLocationsEnabled?: boolean;
  PartnerNetworkEnabled?: boolean;
  PaymentModuleEnabled?: boolean;
  PublicProfileEnabled?: boolean;
  RemoveBrandingEnabled?: boolean;
  StatisticsEnabled?: boolean;
}

export enum PricingType {
  Room = 'Room',
  Item = 'Item',
}

export enum PricingUnitType {
  Day = 'Day',
  Hour = 'Hour',
}

export enum ReservationStatus {
  All = 'All',
  Approved = 'Approved',
  Canceled = 'Canceled',
}

export interface SodaCalendarElement {
  room?: Room;
  reservations?: Reservation[];
  item?: RentableItem;
  rentals?: Rental[];
  groupedItems?: Rental[];
}

export interface Pricing {
  id?: string;
  name?: string;
  type?: string;
  unit?: string;
  defaultPrice?: number;
  room_price_schema?: any[];
  item_price_schema?: RentableItem[];
  price_schema_config?: PricingConfig[];
  _id?: string;
}

export interface PricingConfig {
  id?: string;
  startDate?: string;
  startTime?: string;
  endDate?: string;
  endTime?: string;
  price?: number;
  minStay?: number;
  price_schema?: Pricing;
}

export interface PriceCalculation {
  discount?: number;
  netPrice?: number;
  optionsNetPrice?: number;
  totalPrice?: number;
  vat?: number;
  error?: string;
  erorr?: string;
}

export interface KeyValuePair {
  key?: string;
  value?: string;
}
