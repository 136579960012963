import { createAction, props } from '@ngrx/store';
import { User } from '@shared-library-models/api-interfaces';

export const loginUser = createAction(
  '[User] Login User',
  props<{ email: string; password: string; persist: boolean }>()
);
export const loginUserWithCognito = createAction(
  '[User] Login User With Cognito',
  props<{ access_token: string; id_token: string; persist: boolean }>()
);

export const registerUser = createAction(
  '[User] Register User',
  props<{ firstName: string; lastName: string; email: string; password: string }>()
);

export const logoutUser = createAction(
  '[User] Logout User'
);

export const loginUserSuccess = createAction(
  '[User] Login User Success',
  props<{ user: User }>()
);

export const loginUserFailure = createAction(
  '[User] Login User Failure',
  props<{ error: any }>()
);

export const confirmationViewed = createAction(
  '[User] Login User Failure',
  props<Record<string, unknown>>()
);

export const registerUserSuccess = createAction(
  '[User] Register User Success',
  props<Record<string, unknown>>()
);

export const registerUserFailure = createAction(
  '[User] Register User Failure',
  props<{ error: any }>()
);

