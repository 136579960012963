import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardComponent } from './card.component';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
    declarations: [CardComponent],
    imports: [CommonModule, MatCardModule, MatIconModule, MatTooltipModule],
    exports: [CardComponent]
})
export class CardModule {}
