import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'moment',
})
export class MomentPipe implements PipeTransform {
  transform(value: any, ...args) {
    if (!args || !args[0]) {
      const today = new Date().toDateString();
      const tomorrow = new Date(new Date().setDate(new Date().getDate() + 1)).toDateString();
      const compare = new Date(value).toDateString();
      if (today === compare || tomorrow === compare) {
        const _value = moment(value).calendar().split(' ')[0];
        return _value;
      } else {
        const _value = moment(value).format('DD.MM.dddd').split('.');
        return `${_value[0]}.${_value[1]}. ${_value[2].substring(0, 3)}`;
      }
    } else {
      return moment(value).format(args[0]);
    }
  }
}
