export interface UploadFileData {
  maximumSize: number;
  allowedFormats: string[];
  dimensions?: string;
}

export interface PillowFilter {
  label?: string;
  icon?: string;
  value?: string;
}

export interface QueryParamsSearch {
  location?: string;
  search?: string;
}

export interface StrapiFile {
  createdAt: Date;
  ext: string;
  formats: { thumbnail: { name: string } };
  hash: string;
  height: number;
  id: string;
  mime: string;
  name: string;
  provider: string;
  provider_metadata: {
    public_id: string;
    resource_type: string;
  };
  related: any[];
  size: number;
  updatedAt: Date;
  url: string;
  width: number;
  __v: number;
  _id: string;
}

export interface PopupAlertData {
  title: string;
  text: string;
  cancelActionText: string;
  confirmActionText: string;
  color?: string;
}

export interface Device {
  _id?: string;
  name: string;
  userID?: string;
}

export interface User {
  _id: string;
  email: string;
  username?: string;
  createdAt?: Date;
  image?: StrapiFile;
  firstname: string;
  lastname: string;
  confirmed?: boolean;
  blocked?: boolean;
  fullName?: string;
  user_stripe_information?: any;
  stripeAccountId?: string;
  role?: any;
  phone?: string;
  PasswordChangeRequired?: boolean;
  public_profile_setting?: PublicProfileSettings;
}

export interface PublicProfileSettings {
  id?: string;
  _id?: string;
  aboutSectionDescription?: string;
  aboutSectionImage?: StrapiFile[];
  heroImage: StrapiFile[];
  heroSectionDescription?: string;
  createdAt?: Date;
  isAboutSectionEnabled?: boolean;
  isRoundedButtons?: boolean;
  primaryColor: string;
  secondaryColor?: string;
  updatedAt?: Date;
  user?: string;
  navigationLogo?: StrapiFile;
}

export interface UserDto {
  user: User;
  token: string;
  refreshToken: string;
}

export interface PillowData {
  name: string;
}

export interface StartColumnsInfo {
  label: string;
  property?: string;
  widthPx: string;
  leftPx: number;
  group?: string;
}

export interface EndColumnsInfo {
  label: string;
  property?: string;
  widthPx: string;
  rightPx: number;
  group?: string;
}