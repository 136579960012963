<div class="generic-table">
  <div class="generic-table__progress">
    <mat-progress-bar [mode]="'indeterminate'" *ngIf="loading"></mat-progress-bar>
  </div>

  <ng-content select="hf-generic-table-header"></ng-content>
  <ng-content select="hf-generic-table-data"></ng-content>
</div>

<div class="footer-pagination">
  <hf-generic-table-pagination *ngIf="showPagination" [page]="tableOptions?.page" [pageSize]="tableOptions?.pageSize" [length]="tableOptions?.totalItems" (paginationChanged)="paginationChanged.emit($event)"></hf-generic-table-pagination>
</div>
