import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { UserEffects } from './+store/effects/user.effects';
import { metaReducers, reducers } from './+store/reducers';
import { environment } from '../environments/environment';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { RouterState, StoreRouterConnectingModule } from '@ngrx/router-store';
import { SharedLibraryModule } from 'honeyfield-shared-library';
import { CoreModule } from './core';
import { SharedModule } from '@needit-shared';
import { ToastBarComponent } from 'honeyfield-component-library';
import { LoginComponent } from './login';
import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io';
import { HttpsRequestInterceptor } from './core/interceptors/httprequest.interceptor';

const config: SocketIoConfig = { url: environment.apiEndpointV2, options: {} };

@NgModule({
    declarations: [AppComponent],
    imports: [
        AppRoutingModule,
        BrowserModule.withServerTransition({ appId: 'serverApp' }),
        BrowserAnimationsModule,
        HttpClientModule,
        SharedLibraryModule.forRoot(environment),
        StoreModule.forRoot(reducers, {
            metaReducers,
            runtimeChecks: {
                strictStateImmutability: true,
                strictActionImmutability: true,
            },
        }),
        !environment.production ? StoreDevtoolsModule.instrument() : [],
        StoreRouterConnectingModule.forRoot({
            routerState: RouterState.Minimal,
        }),
        EffectsModule.forRoot([UserEffects]),
        CoreModule,
        SharedModule,
        SocketIoModule.forRoot(config)
    ],
    bootstrap: [AppComponent],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpsRequestInterceptor,
            multi: true,
        },
    ]
})
export class AppModule { }
