<div [ngClass]="coverStyle ? 'slider-content slider-content--details' : 'slider-content'">
  <hf-icon-button
    *ngIf="coverStyle"
    class="slider-back-container"
    styleType="secondary"
    hfColor="grayscale"
    icon="keyboard_arrow_left"
    (click)="goBack()"
  ></hf-icon-button>
  <div *ngIf="productImages.length > 1 && !utilService.isMobile" class="carousel-btn">
    <span class="btn">
      <mat-icon (click)="$event.stopPropagation(); scrollLeft()" [inline]="true">keyboard_arrow_left</mat-icon>
    </span>
    <span></span>
    <span class="btn">
      <mat-icon (click)="$event.stopPropagation(); scrollRight()" [inline]="true">keyboard_arrow_right</mat-icon>
    </span>
  </div>
  <div class="not-available" *ngIf="!isAvailable">
    <span>{{'GENERAL.AVAILABLE_OTHER_DAY' | translate}}</span>
  </div>
  <div #slider [ngClass]="coverStyle ? 'slider-container slider-container--details' : 'slider-container'">
    <ng-container *ngIf="productImages.length; else defaultImage">
      <ng-container *ngFor="let image of productImages; let i = index">
        <img [ngClass]="coverStyle ? 'slider-img slider-img--details' : 'slider-img'" [src]="image?.url" />
      </ng-container>
    </ng-container>
    <ng-template #defaultImage>
      <img [ngClass]="coverStyle ? 'slider-img slider-img--details' : 'slider-img'" [src]="isAvailable ? placeholder : placeholderNotAvailable" />
    </ng-template>
  </div>
</div>
