<mat-accordion [formGroup]=filterForm>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{"GENERAL.LESSOR" | translate}}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <mat-checkbox color="primary">{{"GENERAL.PERSON"}}</mat-checkbox>
    <mat-checkbox color="accent">{{"GENERAL.BUSINESS"}}</mat-checkbox>
  </mat-expansion-panel>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{"GENERAL.PRICE" | translate}}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <hf-input-text width="116px" placeholder="Min"></hf-input-text>
    <hf-input-text width="116px" placeholder="Max"></hf-input-text>
  </mat-expansion-panel>
  <mat-expansion-panel>
    <mat-expansion-panel-header>{{"GENERAL.REVIEWS" | translate}}</mat-expansion-panel-header>
    <mat-icon>star</mat-icon>
    <mat-icon>star</mat-icon>
    <mat-icon>star</mat-icon>
    <mat-icon>star</mat-icon>
    <mat-icon>star_border</mat-icon>
  </mat-expansion-panel>
  <mat-expansion-panel>
    <mat-expansion-panel-header>{{"GENERAL.REVIEWS" | translate}}</mat-expansion-panel-header>
    <hf-checkbox label="whatever" (change)="checked()" formControlName="rating"></hf-checkbox>
  </mat-expansion-panel>
</mat-accordion>
