<div *ngIf="utilService.isMobile" class="mobile-menu" id="mobile-menu">
  <div
    (click)="navigateToProducts()"
    [ngClass]="isProducts ? 'mobile-menu__icon mobile-menu__icon--selected' : 'mobile-menu__icon'"
  >
    <mat-icon [inline]="true">search</mat-icon>
    <p class="mobile-menu__text">{{ 'GENERAL.SEARCH' | translate }}</p>
  </div>
  <div
    (click)="navigateToMessages()"
    [ngClass]="isMessage ? 'mobile-menu__icon mobile-menu__icon--selected' : 'mobile-menu__icon'"
  >
    <mat-icon [inline]="true">email</mat-icon>
    <p class="mobile-menu__text">{{ 'GENERAL.MESSAGE' | translate }}</p>
  </div>
  <div
    (click)="navigateToProfile()"
    [ngClass]="isProfile ? 'mobile-menu__icon mobile-menu__icon--selected' : 'mobile-menu__icon'"
  >
    <mat-icon [inline]="true">person_outline</mat-icon>
    <p class="mobile-menu__text">{{ 'GENERAL.PROFILE' | translate }}</p>
  </div>
</div>
