<div class="no-result">
      <span class="no-result__heading">{{ "SEARCH_RESULTS.SORRY" | translate}}
      </span>
  <span class="no-result__sub-heading">{{ "SEARCH_RESULTS.NO_RESULTS" | translate}}
      </span>
  <span class="no-result__sub-heading">{{ "SEARCH_RESULTS.CHECK_SPELLING" | translate}}
      </span>
  <hf-text-button class="no-result__button"
                  hfColor="primary"
                  [text]="('SEARCH_RESULTS.BACK' | translate)"
                  [width]="'272px'"
                  (click)="reloadProducts()">
  </hf-text-button>
  <img src="./assets/no_results.png" alt="" class="no-result__img">
</div>
