import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { HFGroupSelectItem } from '../../interfaces';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'hf-group-select',
  templateUrl: './group-select.component.html',
  styleUrls: ['./group-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => GroupSelectComponent),
    },
  ],
})
export class GroupSelectComponent implements OnInit, ControlValueAccessor {
  @Input() placeholder: string;
  @Input() icon: string;
  @Input() error: string;
  @Input() required: boolean;
  @Input() disabled = false;
  @Input() showImages = false;
  @Input() isMultiple = false;
  @Input() max = null;
  @Input() source: HFGroupSelectItem[] = [];
  @Output() selectionChange = new EventEmitter<any>();

  selectInput = new UntypedFormControl({ disabled: this.disabled });

  private onChange: (value: string | Array<any>) => void;
  private onTouched: () => void;

  constructor(public translate: TranslateService) {}

  ngOnInit(): void {}

  writeValue(value: string | Array<any>): void {
    this.selectInput.setValue(value);
  }

  registerOnChange(fn: any | Array<any>): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any | Array<any>): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.selectInput.disable();
    } else {
      this.selectInput.enable();
    }
  }

  doInput(value) {
    this.onChange(value);
  }

  doBlur() {
    this.onTouched();
  }

  onSelection(event: MatSelectChange) {
    if (event !== undefined) {
      this.selectionChange.emit(event);
    }
  }
}
