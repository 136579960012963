import { Component, Input, OnInit, HostListener, Output, EventEmitter } from '@angular/core';
import { HFMenuItem } from '../../interfaces/hf-menu-item.interface';

@Component({
  selector: 'hf-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss']
})
export class SideBarComponent implements OnInit {
  @Input() hfColor: string;
  @Input() source: HFMenuItem[];
  @Input() dropdownItems: HFMenuItem[];
  @Input() fullName: string;
  @Input() actionButtonTitle: string;
  @Input() rightIconUrl: string;
  @Input() showMenu: boolean;
  @Input() showTopMenu = true;
  @Input() removeContentPadding: boolean;
  @Input() showPages: boolean;
  @Input() showBackgroundAssets: boolean;
  @Input() colorScheme: string;
  @Input() addGapToMenuItems = false;
  @Input() hideBurger = false;
  @Input() removeBottomPadding = false;
  @Input() useAvatarImage: string;
  @Output() subitemAction: EventEmitter<any> = new EventEmitter();

  public isMobile: boolean;
  public drawerOpened: boolean;
  
   @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isMobile = window.innerWidth <= 768;
  }

  constructor() {
  }

  ngOnInit(): void {
    this.isMobile = window.innerWidth <= 768;
  }

  onSubitemAction(event) {
    this.subitemAction.emit(event);
  }

  onSubmenuCollpased(pageLabel) {
    this.source.forEach(page => {
      if(page.label === pageLabel) {
        page.submenuCollapsed = !page.submenuCollapsed;
      }else {
        page.submenuCollapsed = true;
      }
    });
  }

  toggleDrawer() {
    if(this.showMenu && this.showTopMenu) {
      this.drawerOpened = !this.drawerOpened;
    }
  }
}
