import { Component, forwardRef, Input, OnChanges, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, UntypedFormControl } from '@angular/forms';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { Subject } from 'rxjs';
import * as Constants from '../../constants';

@Component({
  selector: 'hf-input-text',
  templateUrl: './input-text.component.html',
  styleUrls: ['./input-text.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => InputTextComponent)
    }
  ]
})
export class InputTextComponent implements OnInit, OnChanges, ControlValueAccessor {
  @Input() placeholder: string;

  @Input() iconAlign: string;
  @Input() icon: string;
  @Input() hint: string;
  @Input() error: string;
  @Input() type = 'text';
  @Input() min: string;
  @Input() max: string;
  @Input() hasRegex: boolean;
  @Input() width: string;
  @Input() required: boolean;
  @Input() matAutocomplete: MatAutocomplete;
  @Input() autocompleteData: any;
  @Input() textarea: boolean;
  @Input() set touched(value: number) {
    if (value) {
     this.textInput.markAsTouched();
     this.stateChanges.next();
     this.textInput.updateValueAndValidity();
    }
  }

  public get disabled(): boolean {
    return this._disabled;
  }

  @Input()
  public set disabled(value: boolean) {
    this._disabled = value;
    if (value) {
      this.textInput.disable();
    } else {
      this.textInput.enable();
    }
  }

  @Input()
  public set value(value: string | any) {
    this._value = value;
    this.textInput.patchValue(value);
    this.stateChanges.next();
  }

  public get value(): string | any {
    return this._value;
  }

  numPatternCheck: boolean;
  specialCharPattern: boolean;
  uppercaseCharacterPattern: boolean;
  minLengthPattern: boolean;
  urlPatternCheck: boolean
  minMaxCheck: boolean;

  public textInput = new UntypedFormControl('');

  private _value: string | any;
  private _disabled: boolean;

  stateChanges = new Subject<void>();

  private onChange: (name: string) => void;
  private onTouched: () => void;

  constructor() {
  }

  ngOnInit(): void {

  }

  ngOnChanges() {
    this.stateChanges.next();
  }

  writeValue(input: any): void {
    this.textInput.setValue(input);
    this.stateChanges.next();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if(isDisabled) {
      this.textInput.disable();
    } else {
      this.textInput.enable();
    }
  }

  doInput(event) {
    this.onChange(event.target.value);

    if (this.hasRegex) {
      this.validateInput(this.textInput.value);
    }

    if(this.type.toLowerCase() === 'url'){
      this.validateUrlInput(this.textInput.value);
    }
    if(this.min || this.max) {
      this.validateInputMinMax(this.textInput.value);
    }
  }

  doBlur() {
    this.onTouched();
  }

  validateInput(input: string) {
    this.numPatternCheck = Constants.numberPattern.test(input);
    this.specialCharPattern = Constants.specialCharacterPattern.test(input);
  }

  validateUrlInput(input: string) {
    this.urlPatternCheck = !Constants.urlPattern.test(input);
  }

  validateInputMinMax(input: string) {
    this.minMaxCheck = input < this.min || input > this.max;
  }
}
