import { Injectable } from '@angular/core';
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SEOService {

  public lang: string;

  constructor(
    private meta: Meta, 
    private title: Title,
    private translateService: TranslateService,
    private router: Router,
    private activatedRoute: ActivatedRoute) {}

  initialize() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd),
        map(() => {
          let route = this.activatedRoute;
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        map((route: ActivatedRoute) => {

          this.lang = this.translateService.currentLang;

          if (this.lang && this.translateService.translations[this.lang]) {
            const data: any = route.data;

            let title = ''; //= 
            let metaDescription = ''; 
            const metaRobots = data.value.metaRobots ? data.value.metaRobots : 'all';

            const titleKey = data.value.title ? data.value.title : '';
            title = `NEEDIT${titleKey.length ? ' - ' : ''}${this.translate(titleKey)}`;

            const metaDescriptionKey = data.value.metaDescription ? data.value.metaDescription : '';
            metaDescription = metaDescriptionKey.length ? this.translate(metaDescriptionKey) : metaDescriptionKey;

            return {
              title,
              metaDescription,
              metaRobots
            };

          } else {
            return {
            }
          }
        }))
      .subscribe((data) => {
        if (data.title) {
          this.title.setTitle(data.title);
        }

        if (data.metaDescription) {
          this.meta.updateTag({ name: 'description', content: data.metaDescription });
        }

        if (data.metaRobots) {
          this.meta.updateTag({ name: 'robots', content: data.metaRobots });
        }
      });
  }

  public setPageMeta(title: string, tags: MetaDefinition[]) {
    this.title.setTitle(`NEEDIT - ${title}`);

    this.meta.addTag({ date: 'hallo' });
    this.meta.addTags(tags);
  }

  public translate(key: string): string {
    key = key.split('.')[1];
    if(key){
      const translation = this.translateService.translations[this.lang].SEO?.[key];
      if (translation) {
        return translation;
      } else {
        return key;
      }
    }else{
      return '';
    }
  }
}
