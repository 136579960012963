import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs';
import { AuthService } from '../../core/services';
import { SnackBarService } from '../../services/snack-bar.service';

@Component({
  selector: 'ned-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.scss'],
})
export class CallbackComponent implements OnInit {
  constructor(
    @Inject(PLATFORM_ID) private platformId: string,
    private route: ActivatedRoute,
    private router: Router,
    private snackBar: SnackBarService
  ) {}

  ngOnInit(): void {
    this.route.fragment
      .pipe(
        map((fragment) => new URLSearchParams(fragment)),
        map((params) => ({
          access_token: params.get('access_token'),
          id_token: params.get('id_token'),
          error: params.get('error'),
          expires_in: params.get('expires_in'),
          error_description: params.get('error_description'),
        }))
      )
      .subscribe((res) => {
        if (res.error && res.error_description) {
          this.snackBar.presentToast(res.error, res.error_description, 'cancel', 'error');
          this.router.navigate(['./login']);
        } else {
          AuthService.token = res.id_token;
          if (isPlatformBrowser(this.platformId)) {
            if (localStorage.getItem('persist')) {
              localStorage.setItem('token', res.id_token);
            } else {
              sessionStorage.setItem('token', res.id_token);
            }
          }
          this.router.navigate(['./products']);
        }
      });
  }
}
