import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RentableItem } from 'honeyfield-shared-library';
import { StrapiFile } from 'honeyfield-component-library';

@Component({
  selector: 'ned-product-tile',
  templateUrl: './product-tile.component.html',
  styleUrls: ['./product-tile.component.scss'],
})
export class ProductTileComponent implements OnInit {
  @Input() item: RentableItem;
  @Input() emptyTile = false;
  @Input() isEditable = false;
  @Input() searchedLocation: string;
  @Input() showDescription = true;
  @Input() uploadPermission = true;
  @Input() isAvailable = true;

  @Output()
  showDetails: EventEmitter<RentableItem> = new EventEmitter<RentableItem>();

  @Output()
  editClicked: EventEmitter<RentableItem> = new EventEmitter<RentableItem>();

  placeholder = 'assets/image-placeholder.png';
  priceUnit = '';
  public itemImages: StrapiFile[];

  constructor() {}

  ngOnInit(): void {
    if (this.item?.images) {
      this.itemImages = this.item?.images;
    }
    if (this.item?.priceSchema?.unit) {
      this.priceUnit = `GENERAL.PER_${this.item?.priceSchema?.unit?.toLocaleUpperCase()}`;
    }
  }

  uploadProduct() {
    console.log('Product uploaded!');
  }
}
