import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'ned-empty-search',
  templateUrl: './empty-search.component.html',
  styleUrls: ['./empty-search.component.scss'],
})
export class EmptySearchComponent implements OnInit {
  constructor(private router: Router, private route: ActivatedRoute) {}

  ngOnInit(): void {}

  reloadProducts() {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {},
    });
  }
}
