import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Status } from '@soda-models/partner-request';
import { PartnerRequestResponse } from '@soda-models/partner-request-info';
import { RequestData } from '@soda-models/request-data';
import { UtilsService } from 'honeyfield-component-library';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RequestService {
  private apiEndpoint = `${this.environment.apiEndpoint}/requests`;

  constructor(private http: HttpClient, @Inject('env') private environment) {}

  public getRequests(query: any = null, pagination: any = null): Observable<RequestData[]> {
    let url = this.apiEndpoint;
    const limit = pagination && pagination.limit ? `&_limit=${pagination.limit}` : '';
    const start = pagination && pagination.start ? `&_start=${pagination.start}` : '';

    if (query) {
      const sort = query.sort;
      delete query.sort;
      url = UtilsService.createUrlFromParams(url, query, true);
      url = `${url}_sort=${sort}:asc${limit}${start}`;
    } else {
      url = `${url}?_sort=start:asc${limit}${start}`; //arrival instead of start
    }

    return this.http.get<RequestData[]>(url);
  }

  public getRequest(id: string): Observable<RequestData> {
    return this.http.get<RequestData>(`${this.apiEndpoint}/rental/${id}`);
  }

  public getRequestsCount(query: any = null): Observable<number> {
    let url = `${this.apiEndpoint}/count`;

    if (query) {
      const sort = query.sort;
      delete query.sort;
      url = UtilsService.createUrlFromParams(url, query, true);
      url = `${url}_sort=${sort}:asc`;
    } else {
      url = `${url}?_sort=start:asc`; //arrival instead of start
    }

    return this.http.get<number>(url);
  }

  public addRequest(request: RequestData): Observable<RequestData> {
    return this.http.post<RequestData>(this.apiEndpoint, request);
  }

  public updateRequest(requestId: string, request: RequestData): Observable<RequestData> {
    const url = `${this.apiEndpoint}/${requestId}`;
    return this.http.put<RequestData>(url, request);
  }

  public deleteRequest(requestId: string): Observable<any> {
    const url = `${this.apiEndpoint}/${requestId}`;
    return this.http.delete<RequestData>(url);
  }

  public approveRequest(requestId: string): Observable<RequestData> {
    const url = `${this.apiEndpoint}/approve/${requestId}`;
    return this.http.put<RequestData>(url, {});
  }

  public rejectRequest(requestId: string): Observable<RequestData> {
    const url = `${this.apiEndpoint}/reject/${requestId}`;
    return this.http.put<RequestData>(url, {});
  }

  public cancelRequest(requestId: string): Observable<RequestData> {
    const url = `${this.apiEndpoint}/cancel/${requestId}`;
    return this.http.put<RequestData>(url, {});
  }

  getRequestBooking(
    isReceived: boolean = false,
    start: string,
    end: string,
    status: string,
    page = 1,
    itemsPerPage = 10,
    allRequests: boolean = false,
    searchText: string,
    sortBy: string = null
  ): Observable<PartnerRequestResponse> {
    const apiUrl = `${this.apiEndpoint}/bookings?`;
    let url = '';
    if (status === Status.All) {
      status = null;
    }

    if (searchText && status) {
      url = `${apiUrl}${
        allRequests ? '' : `isReceived=${isReceived}`
      }&start=${start}&end=${end}&searchText=${searchText}&status=${status}&page=${page}&itemsPerPage=${itemsPerPage}`;
    } else if (status) {
      url = `${apiUrl}${
        allRequests ? '' : `isReceived=${isReceived}`
      }&start=${start}&end=${end}&status=${status}&page=${page}&itemsPerPage=${itemsPerPage}`;
    } else if (searchText) {
      url = `${apiUrl}${
        allRequests ? '' : `isReceived=${isReceived}`
      }&start=${start}&end=${end}&searchText=${searchText}&page=${page}&itemsPerPage=${itemsPerPage}`;
    } else {
      url = `${apiUrl}${
        allRequests ? '' : `isReceived=${isReceived}`
      }&start=${start}&end=${end}&page=${page}&itemsPerPage=${itemsPerPage}`;
    }
    if (sortBy) {
      url = `${url}&sortQuery=${sortBy}`;
    }
    return this.http.get<PartnerRequestResponse>(url);
  }

  getRequestRentals(
    isReceived: boolean = false,
    start: string,
    end: string,
    status: string,
    page = 1,
    itemsPerPage = 10,
    allRequests: boolean = false,
    searchText: string = null,
    sortBy: string = null
  ): Observable<PartnerRequestResponse> {
    const apiUrl = `${this.apiEndpoint}/rentals?`;
    let url = ``;
    if (status === Status.All) {
      status = null;
    }

    if (searchText && status) {
      url = `${apiUrl}${
        allRequests ? '' : `isReceived=${isReceived}`
      }&start=${start}&end=${end}&searchText=${searchText}&status=${status}&page=${page}&itemsPerPage=${itemsPerPage}`;
    } else if (status) {
      url = `${apiUrl}${
        allRequests ? '' : `isReceived=${isReceived}`
      }&start=${start}&end=${end}&status=${status}&page=${page}&itemsPerPage=${itemsPerPage}`;
    } else if (searchText) {
      url = `${apiUrl}${
        allRequests ? '' : `isReceived=${isReceived}`
      }&start=${start}&end=${end}&searchText=${searchText}&page=${page}&itemsPerPage=${itemsPerPage}`;
    } else {
      url = `${apiUrl}${
        allRequests ? '' : `isReceived=${isReceived}`
      }&start=${start}&end=${end}&page=${page}&itemsPerPage=${itemsPerPage}`;
    }

    if (sortBy) {
      url = `${url}&sortQuery=${sortBy}`;
    }

    return this.http.get<PartnerRequestResponse>(url);
  }

  updateStatus(requestId: string, status: string): Observable<any> {
    const url = `${this.apiEndpoint}/status/${requestId}`;
    return this.http.put<any>(url, { status });
  }

  public getRequestsTotal(): Observable<number> {
    const url = `${this.apiEndpoint}/total`;
    return this.http.get<number>(url);
  }

  public requestOverlap(requestData: RequestData, existingRequestIds: string[] = []): Observable<boolean> {
    const url = `${this.apiEndpoint}/overlap`;
    return this.http.post<boolean>(url, {
      requestData,
      ids: existingRequestIds,
    });
  }

  public getInvoice(rentalNumber: string): Observable<Blob> {
    const url = `${this.environment.apiEndpoint}/rentals/download_confirmation`;
    const body = { rentalNumber };
    return this.http.post(url, body, { responseType: 'blob' });
  }
}
