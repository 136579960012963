import { Component, Input, OnInit } from '@angular/core';
import { UtilsService } from 'honeyfield-component-library';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'ned-go-to-soda-banner',
  templateUrl: './go-to-soda-banner.component.html',
  styleUrls: ['./go-to-soda-banner.component.scss'],
})
export class GoToSodaBannerComponent implements OnInit {
  public isMobile = false;

  @Input() message: string;
  @Input() message2: string;
  @Input() styleVertical = false;

  constructor(private utilsService: UtilsService) {
    this.isMobile = this.utilsService.isMobile;
  }

  ngOnInit(): void {}

  redirectToSRC() {
    window.location.href = environment.sodaRentalClubUrl;
  }
}
