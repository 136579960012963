import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ProductsService } from '@needit-services';
import { StrapiFile, UtilsService } from 'honeyfield-component-library';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'ned-images-slider',
  templateUrl: './images-slider.component.html',
  styleUrls: ['./images-slider.component.scss'],
})
export class ImagesSliderComponent implements OnInit {
  @Input() productImages: StrapiFile[];
  @Input() coverStyle = false;
  @Input() isAvailable = true;
  @ViewChild('slider', { read: ElementRef }) public sliderContent: ElementRef;

  placeholder = 'assets/image-placeholder.png';
  placeholderNotAvailable = 'assets/image-placeholder-grayscale.png';

  constructor(
    public utilService: UtilsService,
    private location: Location,
    public router: Router
  ) {}

  ngOnInit(): void {
  }

  public scrollRight(): void {
    this.sliderContent.nativeElement.scrollLeft += 300;
  }

  public scrollLeft(): void {
    this.sliderContent.nativeElement.scrollLeft -= 300;
  }

  public goBack() {
    this.location.back();
  }
}
