import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { LoadingIndicationService, SharedService } from '@needit-services';
import { debounceTime } from 'rxjs';

@Component({
  selector: 'ned-auth-layout',
  templateUrl: './auth-layout.component.html',
  styleUrls: ['./auth-layout.component.scss'],
})
export class AuthLayoutComponent implements OnInit {
  @Output() searchTermChanged: EventEmitter<string> = new EventEmitter<string>();
  public isLoading = LoadingIndicationService.isLoading.pipe(debounceTime(1));

  constructor() {
    LoadingIndicationService.isLoading.next(false);
  }

  ngOnInit(): void {}
}
