<div class="content">
  <ng-container *ngIf="!emptyTile">
    <div (click)="showDetails.emit(item)">
      <ned-images-slider class="image-container" [productImages]="itemImages"
                         [isAvailable]="isAvailable"></ned-images-slider>
    </div>
    <div class="details">
      <span class="price">€&nbsp;{{ item?.Price > 0 ? item?.Price : item?.priceSchema?.defaultPrice }}</span>
      <span *ngIf="item?.Price === 0">/ {{ priceUnit | translate }}</span>
      <div class="like">
        <hf-icon-button
          *ngIf="isEditable"
          icon="edit"
          styleType="ghost"
          hfColor="primary"
          (click)="editClicked.emit(item)"
        ></hf-icon-button>
      </div>
    </div>
    <div class="name" (click)="showDetails.emit(item)">
      {{ item?.Name | ellipsis: 35 }}
      <span *ngIf="!item?.AvailabilityStatus" class="disabled"> / {{'GENERAL.DISABLED' | translate}}</span>
      <span *ngIf="!isAvailable" class="disabled"> / {{'GENERAL.NOT_AVAILABLE' | translate}}</span>
    </div>
    <ng-container *ngIf="item?.distance; else location">
      <div class="name d-flex align-items-c location">
        <i class="icon-location"></i>
        {{ item?.distance }} km {{ 'PROFILE.MY_PRODUCTS.AWAY_FROM_ME' | translate }} {{searchedLocation}}
      </div>
    </ng-container>
    <ng-template #location>
      <div class="name d-flex align-items-c location" *ngIf="item?.account_setting">
        <i class="icon-location"></i>
        {{ item?.account_setting?.Street }}
      </div>
    </ng-template>
    <div class="description"
         *ngIf="showDescription">{{ item && item.Description ? (item.Description | ellipsis: 80) : null }}</div>
  </ng-container>

  <div *ngIf="emptyTile" class="empty-tile-container" (click)="uploadProduct()">
    <div class="upload-product">
      <mat-icon inline="true">add</mat-icon>
      <span class="upload-product__title">{{ 'PROFILE.MY_PRODUCTS.ADD_NEW_PRODUCT' | translate }}</span>
      <span class="upload-product__title permission"
            *ngIf="!uploadPermission">{{ 'PROFILE.MY_PRODUCTS.PERMISSION_MESSAGE' | translate }}</span>
    </div>
    <div class="description center-text">
      {{ 'PROFILE.MY_PRODUCTS.CLICK_HERE_FOR_START_RENTING_PRODUCT' | translate }}
    </div>
  </div>
</div>
