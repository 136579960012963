import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';

import { Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { Store } from '@ngrx/store';
import jwt_decode from 'jwt-decode';
import { State } from '../../+store/reducers';
import { registerUserSuccess } from '../../+store/actions/user.actions';

import { environment } from '../../../environments/environment';
import { User, UserDto } from '@shared-library-models/api-interfaces';
import { AccountFeaturesService, RoleLimitationService, SharedService } from 'honeyfield-shared-library';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public static user: User;
  public static userName: string;
  public static token: string;
  public static refreshToken: string;
  public static refreshing = false;

  public loggedUser: Subject<User> = new Subject<User>();

  private apiEndpoint = `${environment.apiEndpoint}`;

  constructor(
    @Inject(PLATFORM_ID) private platformId: string,
    private http: HttpClient,
    private accountFeatures: AccountFeaturesService,
    private store: Store<State>,
    private router: Router,
    private roleLimitationService: RoleLimitationService,
    private sharedService: SharedService
  ) {
    if (isPlatformBrowser(this.platformId)) {
      const persist = localStorage.getItem('persist');
      if (persist) {
        AuthService.token = localStorage.getItem('token');
        AuthService.refreshToken = localStorage.getItem('refreshToken');
        AuthService.user = JSON.parse(localStorage.getItem('user'));
      } else {
        AuthService.token = sessionStorage.getItem('token');
        AuthService.refreshToken = sessionStorage.getItem('refreshToken');
        AuthService.user = JSON.parse(sessionStorage.getItem('user'));
      }
    }
    this.loggedUser.next(AuthService.user);
    this.createUsername();
  }

  private createUsername() {
    AuthService.userName =
      AuthService.user?.firstname || AuthService.user?.lastname
        ? `${AuthService.user.firstname ? AuthService.user.firstname : ''} ${AuthService.user.lastname ? AuthService.user.lastname : ''
        }`
        : AuthService.user?.username
          ? AuthService.user?.username
          : 'N/A';
  }

  public login(email, password, persist): Observable<User> {
    const url = `${this.apiEndpoint}/account/login`;
    return this.http.post<UserDto>(url, { email, password }).pipe(
      tap((result) => this.storeUserData(result, persist)),
      map((userResponse) => {
        const user: User = {
          username: userResponse.user.username,
          email: userResponse.user.email,
          _id: userResponse.user._id,
          createdAt: userResponse?.user?.createdAt,
          firstname: userResponse?.user?.firstname,
          lastname: userResponse?.user?.lastname,
          role: userResponse?.user?.role,
          phone: userResponse?.user?.phone,
          image: userResponse?.user?.image,
          PasswordChangeRequired: userResponse?.user.PasswordChangeRequired,
        };
        if (isPlatformBrowser(this.platformId)) {
          localStorage.setItem('persist', persist);
        }

        this.createUsername();

        this.accountFeatures.getFeatures().subscribe();
        return user;
      })
    );
  }

  public register(firstName, lastName, email, password): Observable<boolean> {
    const url = `${this.apiEndpoint}/account/register`;
    return this.http
      .post<any>(url, { firstName, lastName, email, password })
      .pipe(tap(() => this.store.dispatch(registerUserSuccess({}))));
  }

  public isUserLoggedIn(): boolean {
    return AuthService.token && AuthService.token.length > 0;
  }

  public checkTokenExpirationTime(): number {
    const decoded: any = jwt_decode(AuthService.token);
    if (decoded.exp === undefined) {
      return null;
    }

    const date = new Date(0);
    date.setUTCSeconds(decoded.exp);
    const dif = (date.getTime() - new Date().getTime()) / 1000;

    return dif;
  }

  public logout(): Observable<boolean> {
    AuthService.token = null;
    if (isPlatformBrowser(this.platformId)) {
      sessionStorage.removeItem('token');
      localStorage.removeItem('token');
    }
    AuthService.refreshToken = null;
    if (isPlatformBrowser(this.platformId)) {
      sessionStorage.removeItem('refreshToken');
      localStorage.removeItem('refreshToken');
    }
    AuthService.user = null;
    if (isPlatformBrowser(this.platformId)) {
      sessionStorage.removeItem('user');
      localStorage.removeItem('user');
    }
    this.roleLimitationService.setToLocalStorage(null);
    this.roleLimitationService.roleLimitation.next(null);
    this.sharedService.emitLogout();
    const url = `${this.apiEndpoint}/account/logout`;
    return this.http.get<boolean>(url);
  }

  public initiateForgotPassword(email: string): Observable<any> {
    const url = `${this.apiEndpoint}/account/initiate-forgot-password`;
    return this.http.post<any>(url, { email });
  }

  public resetPassword(email: string, code: string, newPassword: string): Observable<any> {
    const url = `${this.apiEndpoint}/account/reset-password`;
    return this.http.post<any>(url, { email, code, newPassword });
  }

  public changePassword(data: any): Observable<any> {
    const url = `${this.apiEndpoint}/account/change-password`;
    return this.http.post<any>(url, data);
  }

  public refreshToken(): Observable<any> {
    AuthService.refreshing = true;
    const url = `${this.apiEndpoint}/account/refresh`;
    return this.http.post<any>(url, { refreshToken: AuthService.refreshToken }).pipe(
      tap(),
      map((result) => {
        if (!result) {
          this.clearStorage();
          this.router.navigate(['/login']);
        }
        AuthService.token = result.result.idToken.jwtToken;
        AuthService.refreshToken = result.result.refreshToken.token;

        if (isPlatformBrowser(this.platformId)) {
          if (localStorage.getItem('persist')) {
            localStorage.setItem('token', result.result.idToken.jwtToken);
            localStorage.setItem('refreshToken', result.result.refreshToken.token);
          } else {
            sessionStorage.setItem('token', result.result.idToken.jwtToken);
            sessionStorage.setItem('refreshToken', result.result.refreshToken.token);
          }
        }
        AuthService.refreshing = false;
      })
    );
  }

  public clearStorage() {
    AuthService.token = null;
    if (isPlatformBrowser(this.platformId)) {
      sessionStorage.removeItem('token');
      localStorage.removeItem('token');
    }
    AuthService.refreshToken = null;
    if (isPlatformBrowser(this.platformId)) {
      sessionStorage.removeItem('refreshToken');
      localStorage.removeItem('refreshToken');
    }
    AuthService.user = null;
    if (isPlatformBrowser(this.platformId)) {
      sessionStorage.removeItem('user');
      localStorage.removeItem('user');
    }
  }

  private storeUserData(userResponse: UserDto, persist) {
    if (userResponse?.user?.confirmed && userResponse?.user?.blocked === false) {
      AuthService.token = userResponse.token;
      AuthService.refreshToken = userResponse.refreshToken;

      if (userResponse.user) {
        AuthService.user = {
          username: userResponse.user.username,
          email: userResponse.user.email,
          _id: userResponse.user._id,
          createdAt: userResponse.user.createdAt,
          firstname: userResponse.user.firstname,
          lastname: userResponse.user.lastname,
          user_stripe_information: userResponse.user?.user_stripe_information,
          role: userResponse.user.role,
          phone: userResponse.user.phone,
          image: userResponse.user.image,
          PasswordChangeRequired: userResponse.user.PasswordChangeRequired,
        };

        if (isPlatformBrowser(this.platformId)) {
          if (persist) {
            localStorage.setItem('user', JSON.stringify(AuthService.user));
            localStorage.setItem('token', userResponse.token);
            localStorage.setItem('refreshToken', userResponse.refreshToken);
            localStorage.setItem('persist', 'true');
          } else {
            sessionStorage.setItem('user', JSON.stringify(AuthService.user));
            sessionStorage.setItem('token', userResponse.token);
            sessionStorage.setItem('refreshToken', userResponse.refreshToken);
            localStorage.removeItem('persist');
          }
        }
        this.loggedUser.next(AuthService.user);
      } else {
        console.error('Could not load user profile!');
      }
    } else {
      console.error('Email not verified');
    }
  }

  public navigateToLogout() {
    this.clearStorage();
    this.router.navigate(['/login']);
  }

  public createStripeCustomerAndsubscription(body: any) {
    const url = `${this.apiEndpoint}/payment/create-customer-and-subscription`;
    return this.http.post<any>(url, body);
  }

  public customerCreateCardAndChangePaymentMethod(body: any) {
    const url = `${this.apiEndpoint}/payment/change-customer-card`;
    return this.http.post<any>(url, body);
  }

  public getUserSubscriptionInfo() {
    const url = `${this.apiEndpoint}/payment/user-subscription-info`;
    return this.http.get<any>(url);
  }

  public getCurrentLoggedUser() {
    const url = `${this.apiEndpoint}/payment/currentLoggedUser`;

    return this.http.get<User>(url).pipe(
      map((data) => {
        AuthService.user = data;
        this.setUserToStorage(data);

        return data;
      })
    );
  }

  public setUserToStorage(user) {
    AuthService.user = user;
    if (isPlatformBrowser(this.platformId)) {
      const persist = localStorage.getItem('persist');
      if (persist) {
        localStorage.setItem('user', JSON.stringify(AuthService.user));
      } else {
        sessionStorage.setItem('user', JSON.stringify(AuthService.user));
      }
    }
    this.loggedUser.next(user);
  }

  get authUser() {
    return AuthService.user;
  }

  confirmAccount(code) {
    const url = `${this.apiEndpoint}/account/confirm-user?cognito_id=${code}`;
    return this.http.get<any>(url);
  }
}
