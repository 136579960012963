<div class="footer-container" id="footer" *ngIf="!hide">
  <ned-join-now-footer *ngIf="!userLoggedIn"></ned-join-now-footer>
  <div class="content">
    <div class="info">
      <img src="assets/LOGONEEDIT.svg" alt="" class="info__img" />
      <p class="info__text">{{ 'GENERAL.NEEDIT_DESCRIPTION' | translate }}</p>
      <div class="info__social">
        <a href="https://www.facebook.com/needitaustria/" target="_blank">
          <div class="icon"><img src="assets/fb_icon.svg" alt="" /></div>
        </a>
        <a href="https://www.instagram.com/needit.at/" target="_blank">
          <div class="icon"><img src="assets/ig_icon.svg" alt="" /></div>
        </a>
        <!-- <a href="#" target="_blank">
          <div class="icon"><img src="assets/tw_icon.png" alt="" /></div>
        </a> -->
        <a href="https://www.linkedin.com/company/neediteu/" target="_blank">
          <div class="icon"><img src="assets/ln_icon.svg" alt="" /></div>
        </a>
      </div>
    </div>
    <div class="company-content">
      <div class="company-content__legal">
        <p class="company-content__title">{{ 'GENERAL.LEGAL' | translate }}</p>
        <ul>
          <li>
            <a href="https://needit-website.azurewebsites.net/terms" target="_blank">
              {{ 'GENERAL.TERMS' | translate }}
            </a>
          </li>
          <li>
            <a href="https://needit-website.azurewebsites.net/privacy-policy" target="_blank">
              {{ 'GENERAL.PRIVACY_POLICY' | translate }}
            </a>
          </li>
          <li><a href="https://needit-website.azurewebsites.net/imprint" target="_blank">Impressum</a></li>
        </ul>
      </div>
      <div class="company-content__about">
        <p class="company-content__title">{{ 'GENERAL.ABOUT' | translate }}</p>
        <ul>
          <li>
            <a href="https://needit-website.azurewebsites.net/about" target="_blank">
              {{ 'GENERAL.ABOUT' | translate }}
            </a>
          </li>
          <li>
            <a href="https://needit-website.azurewebsites.net/contact" target="_blank">
              {{ 'GENERAL.CONTACT' | translate }}
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="copyright">
      <div class="horline"></div>
      <span class="copyright__text">Copyright ©2022 Needit.at</span>
    </div>
  </div>
</div>
