import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'hf-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss'],
})
export class MenuItemComponent implements OnInit {
  @Input() item: string;
  @Input() iconLeft: string;
  @Input() iconRight: string;
  @Input() url: string;
  @Input() rightIconUrl: string;
  @Input() iconPath: string;
  @Input() disabled: boolean;
  @Input() counterActive = true;
  @Input() count = 0;
  @Input() colorScheme: string;
  @Input() increasePadding = false;
  @Input() subitems = [];
  @Input() subitemsCollapsed;
  @Output() subitemAction: EventEmitter<any> = new EventEmitter();
  @Output() submenuCollapsed: EventEmitter<any> = new EventEmitter();

  iconClicked = false;

  constructor(
    private router: Router, 
    public translate: TranslateService) {}

  ngOnInit(): void {}

  navigate(event: MouseEvent) {
    if (this.subitems?.length) {
      this.submenuCollapsed.emit(this.item);      
      event.stopPropagation();
      event.preventDefault();
    }
    if (this.iconClicked || this.disabled) {
      event.stopPropagation();
      event.preventDefault();
    }
  }

  rightIconClick() {
    this.iconClicked = true;
    this.router.navigateByUrl(this.rightIconUrl);

    setTimeout(() => {
      this.iconClicked = false;
    }, 100);
  }

  subitemNavigate(subitem) {
    if (subitem.locked) {
      this.router.navigateByUrl('payment');
      return;
    }
    if (subitem.href) {
      window.open(subitem.href, "_blank");
      return;
    }
    if (subitem.action) {
      this.subitemAction.emit(subitem.action);
      return;
    }
    if (!subitem.selected) {
      this.router.navigateByUrl(subitem.url);
    }
  }

  get hasSelectedSubitem() {
    return this.subitems?.find(x => x.selected === true);
  }

  get expanded() {
    return !this.subitemsCollapsed && this.subitems?.length;
  }
}
