import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { RentableItem } from '@shared-library-models/api-interfaces';
import { forkJoin, map, Observable, take } from 'rxjs';
import { ProductsService } from './products.service';

@Injectable({
  providedIn: 'root',
})
export class ProductResolverService implements Resolve<RentableItem> {
  constructor(private productService: ProductsService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    return forkJoin([
      this.productService.loadProduct(route.paramMap.get('productId')).pipe(take(1)),
      this.productService.getProductReservedDates(route.paramMap.get('productId')).pipe(take(1))
    ]).pipe(map((data) => {
      const product = data[0];
      const productReservedDates = data[1];

      return {
        product,
        productReservedDates
      };
    }))
  }
}
