import { Component, ElementRef, forwardRef, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'hf-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => SliderComponent)
    }
  ]
})
export class SliderComponent implements OnInit, ControlValueAccessor {

  @Input() min: string;
  @Input() max: string;
  @Input() step: string;
  @Input() hfColor: string;

  public sliderInput = new UntypedFormControl('');

  private onChange = (name: string) => {};
  private onTouched = () => {};

  constructor(private element: ElementRef) { }


  ngOnInit(): void {
    this.element.nativeElement.classList.add('slider-' + this.hfColor);
  }

  writeValue(obj: any): void {
    this.sliderInput.setValue(obj);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if(isDisabled) {
      this.sliderInput.disable();
    } else {
      this.sliderInput.enable();
    }
  }

  doInput() {
    this.onChange(this.sliderInput.value);
  }

  doBlur() {
    this.onTouched();
  }

}
