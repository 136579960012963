import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatSelectionListChange } from '@angular/material/list';
import { TranslateService } from '@ngx-translate/core';
import { HFSelectItem } from '../../interfaces/hf-select.interface';

@Component({
  selector: 'hf-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => SelectComponent),
    },
  ],
})
export class SelectComponent implements OnInit, ControlValueAccessor {
  @Input() placeholder: string;
  @Input() icon: string;
  @Input() error: string;
  @Input() required: boolean;
  @Input() disabled = false;
  @Input() source: HFSelectItem[];
  @Input() isMultiple = false;
  @Input() max = null;
  @Input() highlightLastItem = false;
  @Input() additionalTextColor = '';
  @Input() textAlignment = 'flex-start';
  @Input() showDisabledMessage = true;
  @Input() showEditIcon = false;
  @Input() editTooltip = '';

  @Output() selectionChange = new EventEmitter<any>();
  @Output() editItem = new EventEmitter<any>();

  @Input() set touched(value: number) {
    if (value) {
      this.selectInput.markAsTouched();
      this.selectInput.updateValueAndValidity();
    }
  }

  selectInput = new UntypedFormControl({ disabled: this.disabled });

  private onChange: (value: string | Array<any>) => void;
  private onTouched: () => void;

  constructor(public translate: TranslateService) {}

  ngOnInit(): void {}

  writeValue(value: string | Array<any>): void {
    this.selectInput.setValue(value);
  }

  registerOnChange(fn: any | Array<any>): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any | Array<any>): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.selectInput.disable();
    } else {
      this.selectInput.enable();
    }
  }

  doInput(value) {
    this.onChange(value);
  }

  doBlur() {
    this.onTouched();
  }

  onSelection(event: MatSelectionListChange) {
    if (event !== undefined) {
      this.selectionChange.emit(event);
    }
  }

  onEditClick(item) {
    if(this.selectInput.value) {
      this.editItem.emit(item);
    }
  }
}
