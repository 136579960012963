import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { Socket } from 'ngx-socket-io';
import { Injectable } from '@angular/core';
import { AuthService } from '../core/services';


@Injectable({
  providedIn: 'root'
})
export class SocketsService {
  user = AuthService.user;  

  constructor(private socket: Socket) {
  }

  close() {
    this.socket.disconnect();
  }

  getSocketEventI(eventName: string) {
    return this.socket.fromEvent<any>(eventName);
  }
}