<ng-container [ngSwitch]="styleType">
  <button
    [attr.id]="id ? id : null"
    [type]="type"
    *ngSwitchCase="'primary'"
    mat-flat-button
    class="text-btn btn-primary-type"
    [hfColor]="hfColor"
    [disabled]="disabled"
    [style.width]="fullWidth ? '100%' : width"
    [style.height]="height ? height : 'auto'"
    [style.white-space]="multiLineText ? 'break-spaces' : 'nowrap'"
    [style.line-height]="multiLineText ? '24px' : ''"
  >
    {{ text }}
  </button>
  <button
    [attr.id]="id ? id : null"
    [type]="type"
    *ngSwitchCase="'secondary'"
    mat-stroked-button
    class="text-btn btn-secondary-type"
    [hfColor]="hfColor"
    [disabled]="disabled"
    [style.width]="fullWidth ? '100%' : width"
    [style.height]="height ? height : 'auto'"
    [style.white-space]="multiLineText ? 'break-spaces' : 'nowrap'"
    [style.line-height]="multiLineText ? '24px' : ''"
  >
    {{ text }}
  </button>
  <button
    [attr.id]="id ? id : null"
    [type]="type"
    *ngSwitchCase="'ghost'"
    mat-button
    class="text-btn btn-ghost-type"
    [hfColor]="hfColor"
    [disabled]="disabled"
    [style.width]="fullWidth ? '100%' : width"
    [style.height]="height ? height : 'auto'"
    [style.white-space]="multiLineText ? 'break-spaces' : 'nowrap'"
    [style.line-height]="multiLineText ? '24px' : ''"
  >
    {{ text }}
  </button>
</ng-container>
