import { Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  selector: 'hf-text-button',
  templateUrl: './text-button.component.html',
  styleUrls: ['./text-button.component.scss'],
})
export class TextButtonComponent implements OnInit {
  @HostBinding('style.pointer-events') get pEvents(): string {
    if (this.disabled) {
      return 'none';
    }
    return 'auto';
  }

  @Input() hfColor: string;
  @Input() text: string;
  @Input() disabled: boolean;
  @Input() width: string;
  @Input() height: string;
  @Input() styleType: 'primary' | 'secondary' | 'ghost' = 'primary';
  @Input() fullWidth: boolean;
  @Input() type = 'submit';
  @Input() multiLineText = false;
  @Input() id: string;

  constructor() {}

  ngOnInit(): void {}
}
