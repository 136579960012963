import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { HFMenuItem } from '../../interfaces/hf-menu-item.interface';

@Component({
  selector: 'hf-top-toolbar',
  templateUrl: './top-toolbar.component.html',
  styleUrls: ['./top-toolbar.component.scss']
})
export class TopToolbarComponent implements OnInit {

  @Input() fullName: string;
  @Input() dropdownItems: HFMenuItem[];
  @Input() actionButtonTitle: string;
  @Input() isMobile: boolean;
  @Input() useAvatarImage: string;
  @Input() drawerOpened: boolean;
  @Output() toggleDrawer: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  toggleSidebar() {
    this.toggleDrawer.emit();
  }

  profile() {

  }

  logout() {

  }

}
