import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../+store/reducers';
import { confirmationViewed } from '../../+store/actions/user.actions';
import { Subscription } from 'rxjs';
import { AuthService } from '../../core/services';

@Component({
  selector: 'ned-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit, OnDestroy {

  private subscriptions = new Subscription();

  constructor(private store: Store<State>,
              public auth: AuthService) {
  }

  ngOnInit(): void {

    this.subscriptions.add(this.auth.logout().subscribe());
    this.store.dispatch(confirmationViewed({}));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }


}
