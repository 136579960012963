<div class="single-review">
    <div class="row">
        <div class="image-container">
            <mat-icon>person</mat-icon>
        </div>
        <div class="review-user">
            <div class="title">
                <span class="dark-background">{{ data?.firstName + ' ' + data?.lastName }}</span>
                <span class="lightgray-text">{{ data.reviewDate | date: 'longDate' }}</span>
            </div>
            <div class="review-rating">
                <img src="../../../assets/enabled.png">
                <span>{{ data?.starRating | number: '1.1-1' }}</span>
            </div>
        </div>
    </div>
    <div class="row">
        {{ data?.message }}
    </div>
    <div *ngIf="showSpacer" class="horizontal-spacer"></div>
</div>