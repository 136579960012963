import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthLayoutComponent, MainLayoutComponent } from './core';
import { AuthGuard } from './core/guards';
import { CallbackComponent } from './login/callback/callback.component';
import { LogoutComponent } from './login/logout/logout.component';

export const routes: Routes = [
  {
    path: 'login',
    component: AuthLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./login/login.module').then((m) => m.LoginModule),
      },
    ],
  },
  {
    path: '',
    component: MainLayoutComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'products',
      },
      {
        path: 'products',
        loadChildren: () => import('./pages/products/products-page.module').then((m) => m.ProductDetailPageModule),
      },
      {
        path: 'profile',
        canActivate: [AuthGuard],
        loadChildren: () => import('./pages/profile/profile.module').then((m) => m.ProfileModule),
      },
      {
        path: 'messages',
        // canActivate: [AuthGuard],
        loadChildren: () => import('./pages/messages-page/messages-page.module').then((m) => m.MessagesPageModule),
      },
    ],
  },
  { path: 'callback', component: CallbackComponent, pathMatch: 'full' },
  {
    path: 'logout',
    component: LogoutComponent,
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
      scrollPositionRestoration: 'top',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
