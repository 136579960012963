import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { isPlatformBrowser } from '@angular/common';
import { RoleLimitation } from '@shared-library-models/api-interfaces';

@Injectable({
  providedIn: 'root'
})
export class RoleLimitationService {

  public roleLimitation: BehaviorSubject<RoleLimitation> = new BehaviorSubject<RoleLimitation>(null);
  private roleLimitationEndpoint = `${this.environment.apiEndpoint}/role-limitations`;
  private roleLimitationKey = 'role-limitation-soda';
  private rolePermissons = null;

  constructor(@Inject(PLATFORM_ID) private platformId: string, private http: HttpClient, @Inject('env') private environment) {
    if (isPlatformBrowser(this.platformId)) {
      const storageRoleLimitation = this.getRoleLimitionFromStorage();
      this.roleLimitation.next(storageRoleLimitation);
      this.rolePermissons = storageRoleLimitation;
    }
  }

  public getCurrentUserRoleLimitationById(id: string): Observable<RoleLimitation> {
    if (!id) {
      return of(null);
    }
    const url = `${this.roleLimitationEndpoint}/${id}`;
    return this.http.get<RoleLimitation>(url)
      .pipe(tap(val => {
        this.roleLimitation.next(val);
        this.setToLocalStorage(val);
        this.rolePermissons = val;
      }));
  }

  public getRoleLimitationByRoleId(roleId: string): Observable<RoleLimitation> {
    const url = `${this.roleLimitationEndpoint}?role=${roleId}`;
    return this.http.get<RoleLimitation>(url)
      .pipe(tap(val => {
        this.roleLimitation.next(val);
        this.rolePermissons = val;
      }));
  }

  public setToLocalStorage(roleLimitation: RoleLimitation) {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem(this.roleLimitationKey, JSON.stringify(roleLimitation));
      this.rolePermissons = roleLimitation;
    }
  }

  public getRoleLimitionFromStorage() {
    if (isPlatformBrowser(this.platformId)) {
      return JSON.parse(localStorage.getItem(this.roleLimitationKey));
    }
  }

  public getMainAccountRoleLimitation(): Observable<RoleLimitation> {
    const url = `${this.environment.apiEndpoint}/limitations/main`;
    return this.http.get<RoleLimitation>(url);
  }

  checkPermission(key) {
    return this.rolePermissons[key];
  }
}
