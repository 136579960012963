import { Component, Input, OnInit } from '@angular/core';
import { UserReview } from '@needit-models';

@Component({
  selector: 'ned-user-review',
  templateUrl: './user-review.component.html',
  styleUrls: ['./user-review.component.scss'],
})
export class UserReviewComponent implements OnInit {
  @Input() showSpacer = false;

  @Input() data: UserReview;

  constructor() {}

  ngOnInit(): void {}
}
