import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { RequestData } from '@soda-models/request-data';
import { PricingUnitType, RentableItem, RequestService, SnackBarService } from 'honeyfield-shared-library';
import moment from 'moment';
import { MessageBoxEnum } from 'projects/ui-library/src/lib/building-blocks/chat/components/messages-box/messages-box.component';
import { MessageDto } from 'projects/ui-library/src/lib/building-blocks/chat/models/message.dto';
import { debounceTime, distinctUntilChanged, Subject, takeUntil } from 'rxjs';
import { AuthService } from '../../core/services';
import { MessageService } from '../../services/message.service';
import { ProductsService } from '../../services/products.service';
import { HFSelectItem, UtilsService } from 'honeyfield-component-library';

@Component({
  selector: 'ned-product-reservation',
  templateUrl: './product-reservation.component.html',
  styleUrls: ['./product-reservation.component.scss'],
})
export class ProductReservationComponent implements OnInit {
  _item: RentableItem;
  get item(): RentableItem {
    return this._item;
  }

  @Input() set item(value: RentableItem) {
    this._item = value;
    this.isPriceSchemePerHour = value?.priceSchema?.unit === PricingUnitType.Hour;
  }

  _reservedDates: string[];
  get reservedDates(): string[] {
    return this._reservedDates;
  }

  @Input() set reservedDates(value: string[]) {
    this._reservedDates = value;
  }

  @Input() businessHoursLabels: HFSelectItem[];

  public startHoursLabels: HFSelectItem[];
  public endHoursLabels: HFSelectItem[];

  private destroy$: Subject<boolean> = new Subject<boolean>();

  public query = {
    itemId: null,
    startDate: '',
    startTime: '',
    endDate: '',
    endTime: '',
  };
  public productReservationForm: UntypedFormGroup;

  public dateFormat: string;
  public timeFormat: string;
  public netPrice = 0;
  public price = 0;
  public totalPrice = 0;
  public vat = 0;
  public deposit = 0;
  public defaultPrice = 0;
  public isDayPricingUnit = true;
  public isCalculatePriceContainError = false;
  public itemDefaultStartDate = this.getNearestHourValue();
  public itemDefaultEndDate = this.getNearestHourValue(null, true);
  public isPriceSchemePerHour = false;
  public today = new Date();
  public hasRangeSelected = false;
  public calculationError = null;
  public rentingUnit = '';
  public isMobile;

  constructor(
    public translate: TranslateService,
    private fb: UntypedFormBuilder,
    private productService: ProductsService,
    private snackBar: SnackBarService,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private requestService: RequestService,
    private messageService: MessageService,
    private utilService: UtilsService
  ) {}

  ngOnInit(): void {
    this.isMobile = this.utilService.isMobile;
    this.dateFormat = 'YYYY-MM-DD';
    this.timeFormat = 'HH:mm';
    if (this.item?.priceSchema && this.item?.priceSchema !== null) {
      const priceSchema = this.item.priceSchema;
      this.isDayPricingUnit = priceSchema.unit === PricingUnitType.Day;
      this.rentingUnit = priceSchema?.unit?.toUpperCase();
      this.defaultPrice = priceSchema.defaultPrice;
    }

    this.setHoursLabels();

    this.buildForm();
    this.addValidators();
    this.addSubscriptions();
  }

  private setHoursLabels() {
    if (this.businessHoursLabels) {
      this.startHoursLabels = JSON.parse(JSON.stringify(this.businessHoursLabels));
      this.endHoursLabels = JSON.parse(JSON.stringify(this.businessHoursLabels));
    }
  }

  private buildForm() {
    const params = this.route.snapshot.queryParams;
    const dayPriceCalculation = this.item?.priceSchema?.unit === 'Day';
    if (!dayPriceCalculation) {
      if (params.from && params.to) {
        this.itemDefaultStartDate = new Date(params.from);
        this.itemDefaultEndDate = new Date(params.to);
        const dateWithoutHours = params.from.indexOf('T') === -1;
        if (dateWithoutHours) {
          const d = new Date();
          this.itemDefaultStartDate.setHours(d.getHours(), 0, 0);
          this.itemDefaultEndDate.setHours(d.getHours() + 1, 0, 0);
          this.timesChecker();
        }
      } else {
        this.itemDefaultStartDate = this.getFirstAvailableDate(this.itemDefaultStartDate, false);
        this.itemDefaultEndDate = this.getFirstAvailableDate(this.itemDefaultEndDate, false, true);
        this.timesChecker();
      }
    } else {
      this.itemDefaultStartDate.setHours(0, 0, 0);
      this.itemDefaultEndDate.setHours(0, 0, 0);
      this.itemDefaultStartDate = this.getFirstAvailableDate(this.itemDefaultStartDate);
      this.itemDefaultEndDate = this.getFirstAvailableDate(this.itemDefaultEndDate);
      const from = params.from ?? this.itemDefaultStartDate;
      const to = params.to ?? this.itemDefaultEndDate;
      this.hasRangeSelected = true;
      this.updateDatesParams(from, to);
    }
    this.productReservationForm = this.fb.group({
      netPrice: [null],
      totalPrice: [null],
      deposit: [null],
      vat: [null],
      range: this.fb.group({
        start: [params.from ? params.from : this.itemDefaultStartDate],
        end: [params.to ? params.to : this.itemDefaultEndDate],
      }),
      start: [this.itemDefaultStartDate],
      end: [this.itemDefaultEndDate],
    });
    if (dayPriceCalculation && params.from) {
      this.calculatePrice(params.from, params.to);
    } else {
      this.calculatePrice(this.itemDefaultStartDate, this.itemDefaultEndDate);
    }
  }

  private timesChecker(s?, e?) {
    const start = moment(s ? s : this.itemDefaultStartDate, this.timeFormat);
    const end = moment(e ? e : this.itemDefaultEndDate, this.timeFormat);
    const startHours = this.item?.account_setting?.StartOpeningHours;
    const endHours = this.item?.account_setting?.EndOpeningHours;

    if (startHours && endHours) {
      const opening = moment(startHours, this.timeFormat);
      const closing = moment(end, this.dateFormat);
      const closingHour = endHours.split(':');
      closing.set({ hour: closingHour[0], minute: closingHour[1], second: 0, millisecond: 0 });

      const startInRange = start.isSameOrAfter(opening) && start.isSameOrBefore(closing);
      const endInRange = end.isSameOrBefore(closing) && end.isSameOrAfter(opening);

      if (!startInRange || !endInRange) {
        const openingHours = startHours.split(':');
        if (openingHours.length) {
          const nextDay = moment(new Date()).add(1, 'd');
          nextDay.set({ hour: +openingHours[0], minute: +openingHours[1], second: 0, millisecond: 0 });
          this.itemDefaultStartDate = new Date(nextDay.toLocaleString());
          this.itemDefaultEndDate = this.getNearestHourValue(this.itemDefaultEndDate, true);
        }
      }
    }

    this.updateDatesParams(this.itemDefaultStartDate, this.itemDefaultEndDate, true);
  }

  private addValidators() {
    if (this.isPriceSchemePerHour) {
      this.productReservationForm.controls.start.addValidators([Validators.required]);
      this.productReservationForm.controls.end.addValidators([Validators.required]);
    } else {
      this.productReservationForm.get('range.start').addValidators([Validators.required]);
      this.productReservationForm.get('range.start').addValidators([Validators.required]);
    }
  }

  private addSubscriptions() {
    this.productReservationForm
      .get('start')
      .valueChanges.pipe(distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe((start) => {
        const end = new Date(this.fc.end.value);
        if (!end || start >= end) {
          this.productReservationForm.controls.end.patchValue(this.getNearestHourValue(new Date(start)));
        }
        if (start && this.productReservationForm.value.end && this.productReservationForm.valid) {
          const _end = this.productReservationForm.value.end;
          this.calculatePrice(start, _end);
          this.updateDatesParams(start, _end, true);
        } else {
          this.resetPriceSection();
        }
      });

    this.productReservationForm
      .get('end')
      .valueChanges.pipe(distinctUntilChanged(), debounceTime(100), takeUntil(this.destroy$))
      .subscribe((end) => {
        const start = new Date(this.fc.start.value);
        if (start && end && this.productReservationForm.valid) {
          this.calculatePrice(start, end);
          this.updateDatesParams(start, end, true);
        } else {
          this.resetPriceSection();
        }
      });
    this.productReservationForm.controls.range
      .get('end')
      .valueChanges.pipe(distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe(() => {
        this.productReservationForm.controls.range.updateValueAndValidity();
        const range = JSON.parse(JSON.stringify(this.productReservationForm.controls.range.value));
        if (range.start && range.end && this.productReservationForm.valid) {
          this.calculatePrice(range.start, range.end);
          this.hasRangeSelected = true;
        } else {
          this.resetPriceSection();
          this.hasRangeSelected = false;
        }
        this.updateDatesParams(range.start, range.end);
      });
  }

  updateDatesParams(start, end, t?) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        from: t
          ? `${moment(start).format(this.dateFormat)}T${moment(start).format(this.timeFormat)}`
          : moment(start).format(this.dateFormat),
        to: t
          ? `${moment(end).format(this.dateFormat)}T${moment(end).format(this.timeFormat)}`
          : moment(end).format(this.dateFormat),
      },
      queryParamsHandling: 'merge',
    });
  }

  public getNearestHourValue(date?: Date, addOneHour?: boolean) {
    let d = date;
    if (!d) {
      d = new Date();
    } else {
      d = new Date(date?.toISOString() ?? '');
    }
    const nextAvailableHour = d.getHours() + 1;
    if (addOneHour) {
      return new Date(d.setHours(nextAvailableHour + 1, 0, 0));
    } else {
      return new Date(d.setHours(nextAvailableHour, 0, 0));
    }
  }

  public getMinDate(date?: Date) {
    if (this.productReservationForm.value.start) {
      let today = new Date(this.productReservationForm.value.start);
      if (date) {
        today = new Date(date);
      }
      return today;
    }
  }

  private calculatePrice(startDate: string | Date, endDate: string | Date) {
    if (this.item) {
      this.query.startDate = moment(startDate).format(this.dateFormat);
      this.query.startTime = moment(startDate).format(this.timeFormat);
      this.query.endDate = moment(endDate).format(this.dateFormat);
      this.query.endTime = this.isDayPricingUnit ? '23:59' : moment(endDate).format(this.timeFormat);
      this.query.itemId = this.item._id;
      this.productService
        .calculatePrice(this.query)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (calc) => {
            if (calc.error) {
              this.resetPriceSection();
              this.isCalculatePriceContainError = true;
              this.calculationError = calc;
            } else {
              this.isCalculatePriceContainError = false;
              this.totalPrice = +calc.totalPrice;
              this.price = this.defaultPrice;
              this.vat = +calc.vat;
              this.netPrice = +calc.netPrice;
              this.deposit = this.item.Deposit;
              this.calculationError = null;
            }
            if (!calc.totalPrice) {
              this.resetPriceSection();
            }
          },
          (err) => {}
        );
    }
  }

  private resetPriceSection() {
    this.totalPrice = 0;
    this.price = 0;
    this.netPrice = 0;
    this.vat = 0;
    this.deposit = 0;
  }

  sendRequest() {
    if (this.isCalculatePriceContainError) {
      const title = 'ERRORS.ERROR';
      const message = 'ERRORS.MINIMUM_STAY_POLICY';
      this.snackBar.presentToast(title, message, 'cancel', 'error');
    } else if (this.authService.isUserLoggedIn()) {
      let request: RequestData = {
        rentable_item: this.item._id,
        start: null,
        end: null,
        startDate: null,
        startTime: null,
        endDate: null,
        endTime: null,
        firstName: AuthService.user.firstname,
        lastName: AuthService.user.lastname,
        request_recipient: this.item.user._id,
        totalPrice: this.totalPrice,
      };
      if (this.isPriceSchemePerHour) {
        const start = this.productReservationForm.value.start;
        const end = this.productReservationForm.value.end;
        request = {
          ...request,
          start: start.toISOString(),
          end: end.toISOString(),
          startDate: moment(start).format(this.dateFormat),
          startTime: moment(start).format(this.timeFormat),
          endDate: moment(end).format(this.dateFormat),
          endTime: moment(end).format(this.timeFormat),
        };
      } else {
        const range = JSON.parse(JSON.stringify(this.productReservationForm.controls.range.value));
        request = {
          ...request,
          start: range.start,
          end: range.end,
          startDate: moment(range.start).format(this.dateFormat),
          startTime: moment(range.start).format(this.timeFormat),
          endDate: moment(range.end).format(this.dateFormat),
          endTime: '23:59',
        };
      }
      this.requestService.addRequest(request).subscribe(
        (req: any) => {
          let message = '';
          if (req.requestAlreadyExist) {
            message = 'REQUESTS.REQUEST_ALREADY_EXIST';
            const title = 'ERRORS.ERROR';
            this.snackBar.presentToast(title, message, 'cancel', 'error');
          } else {
            this.router.navigate([`/profile/rentings/${req._id}`]);
            message = 'REQUESTS.ADDED';
            const title = 'GENERAL.SUCCESS';
            this.snackBar.presentToast(title, message, 'check_circle', 'positive');

            const chatMessage: MessageDto = {
              message: '',
              receiver: {
                id: req.request_recipient?.id,
              },
              type: MessageBoxEnum.startCommunication,
              file: null,
              requestId: req._id,
            };

            this.messageService
              .saveMessage(chatMessage)
              .pipe(takeUntil(this.destroy$))
              .subscribe(() => {});
          }
        },
        (error) => {
          const title = 'ERRORS.ERROR';
          const msg = 'REQUESTS.CREATE_REQUEST_ERROR';
          this.snackBar.presentToast(title, msg, 'cancel', 'error');
        }
      );
    } else {
      sessionStorage.setItem('authGuardUrl', this.router.url);
      this.router.navigate(['/login']);
    }
  }

  getFirstAvailableDate(date, resetTime = true, addHour = false) {
    let availableDate = null;
    let d = moment(date).format(this.dateFormat);
    for (let i = 0; i < this.reservedDates.length + 1; i++) {
      const reservedDate = this.reservedDates[i];
      if (d !== reservedDate) {
        availableDate = new Date(d);
        if (resetTime) {
          availableDate.setHours(0, 0, 0);
        } else {
          const now = new Date();
          const minutes = now.getMinutes();
          if (addHour || minutes > 30) {
            const additionalHours = minutes > 30 && addHour ? 2 : 1;
            now.setHours(now.getHours() + additionalHours);
          }
          availableDate.setHours(now.getHours(), minutes > 15 && minutes < 30 ? 30 : 0, 0);
        }
        break;
      } else {
        d = moment(d).add(1, 'd').format(this.dateFormat);
      }
    }
    return new Date(availableDate);
  }

  get frm() {
    return this.productReservationForm.value;
  }

  get fc() {
    return this.productReservationForm.controls;
  }

  get hasError() {
    return this.isCalculatePriceContainError || this.totalPrice === 0;
  }

  get isMyItem() {
    return AuthService.user?._id === this.item.user._id;
  }
}
