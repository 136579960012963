import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  Pagination,
  PriceCalculation,
  RentableItem,
  RentableItemCategory,
  RentableItemsData,
  RentableItemSubCategory,
} from '@shared-library-models/api-interfaces';
import { environment } from '../../environments/environment';
import { StrapiFile } from 'honeyfield-component-library';

@Injectable({
  providedIn: 'root',
})
export class ProductsService {
  constructor(private http: HttpClient) {}

  searchProducts(
    query: any,
    searchTerm?: string,
    location?: string,
    pagination: Pagination = null,
    withoutAuth = 'true',
    showAll?: boolean
  ): Observable<RentableItemsData> {
    let url = `${environment.apiEndpoint}/rentable-items/findAll?`;

    if (Object.keys(query).length > 0) {
      if (query.user) {
        url += `&userId=${query.user}`;
      }
      if (query.name) {
        url += `&itemName=${query.name}`;
      }
      if (query.lat && query.lng) {
        url += `&lng=${query.lng}&lat=${query.lat}`;
      }
      if (query.category) {
        url += `&category=${query.category}`;
      }
      if (query.subCategory) {
        url += `&subCategory=${query.subCategory}`;
      }
      if (query.availableFromDate && query.availableToDate) {
        url += `&availableFromDate=${query.availableFromDate}T00:00&availableToDate=${query.availableToDate}T23:59`;
      }
      if (query.page && query.itemsPerPage) {
        url += `&page=${query.page}&itemsPerPage=${query.itemsPerPage}`;
      } else if (pagination) {
        const page = pagination.start / pagination.limit;
        url += `&page=${page + 1}&itemsPerPage=${pagination.limit}`;
      }
    } else if (pagination) {
      const page = pagination.start / pagination.limit;
      url += `&sortQuery=createdAt:-1&page=${page + 1}&itemsPerPage=${pagination.limit}`;
    } else {
      url += `&sortQuery=createdAt:-1`;
    }
    if (showAll) {
      url += `&showAll=true`;
    }
    return this.http.get<RentableItemsData>(url, { headers: { withoutAuth } });
  }

  getNumberOfItems(searchTerm?: string): Observable<number> {
    let url = '';
    if (searchTerm) {
      url = `${environment.apiEndpoint}/rentable-items/count?_where[0][Name_contains]=${searchTerm}`;
    } else {
      url = `${environment.apiEndpoint}/rentable-items/count?`;
    }
    return this.http.get<number>(url, { headers: { withoutAuth: 'true' } });
  }

  loadProduct(id: string): Observable<RentableItem> {
    const url = `${environment.apiEndpoint}/rentable-items/${id}`;
    return this.http.get<RentableItem>(url, { headers: { withoutAuth: 'true' } });
  }

  getProductReservedDates(id: string) {
    const url = `${environment.apiEndpoint}/rentable-items/itemReservedDates/${id}`;
    return this.http.get<string[]>(url, { headers: { withoutAuth: 'true' } });
  }

  public calculatePrice(query: any = null): Observable<PriceCalculation> {
    const url = `${environment.apiEndpoint}/pricings/calculate`;
    return this.http.post<PriceCalculation>(url, query);
  }

  getRentableCategories(): Observable<RentableItemCategory[]> {
    return this.http.get<RentableItemCategory[]>(`${environment.apiEndpoint}/rentable-item-categories`);
  }

  getRentableCategorySubcategories(categoryId: string): Observable<RentableItemSubCategory[]> {
    const url = `${environment.apiEndpoint}/rentable-item-sub-categories/category/${categoryId}`;
    return this.http.get<RentableItemSubCategory[]>(url);
  }
}
