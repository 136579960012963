import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'ned-join-now-footer',
  templateUrl: './join-now-footer.component.html',
  styleUrls: ['./join-now-footer.component.scss'],
})
export class JoinNowFooterComponent implements OnInit {
  constructor(public translate: TranslateService, private router: Router) {}

  ngOnInit(): void {}

  goToRegistration() {
    this.router.navigate(['login'], {
      queryParams: { register: true },
    });
  }
}
