// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiEndpoint: 'https://staging-api.sodarental.club',
  apiEndpointV2: 'https://soda-rental-club-backend-v2-staging.azurewebsites.net',
  // apiEndpoint: 'http://localhost:1337',
  // apiEndpointV2: 'http://localhost:3000',
  strapiPublishableAPIKey:
    'pk_test_51HxZogJ6eUeCLTBYAND5hm4oLS1ZBrbt3vSJRViULjtlk4gl4ZIQwgjTbsGR5GMQYlLlpGvdv1c4bwfrY2IwFvZF003OGeDu1g',
  hideRecaptcha: true,
  firebaseConfig: {},
  COGNITO_DOMAIN: 'https://src-local-auth-eu-west-1.auth.eu-west-1.amazoncognito.com',
  GOOGLE_REDIRECT_URL: 'http://localhost:4200/callback',
  COGNITO_CLIENT_ID: '177o973d260c2p36a3uppto1aq',
  logo: 'assets/LOGONEEDIT.png',
  sodaRentalClubUrl: 'https://staging.sodarental.club/login',
  needitLandingPageUrl: 'https://needit.at',
  currency: '€',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
