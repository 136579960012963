<div class="content">
  <div class="wrapper">
    <div class="column">
      <img class="custom-size pointer" src="assets/LOGONEEDIT_V2.png" (click)="navigateToLandingPage()" />
      <hf-search *ngIf="isPLPActive" [width]="isMobile ? '100%' : '335px'" icon="search"
                 [placeholder]="translate.get('GENERAL.SEARCH_FOR_ITEMS') | async" [formControl]="searchText"
                 (optionSelect)="onSearch($event)" [addBorderRadius]="true" [value]="searchText.value">
      </hf-search>
    </div>
    <div class="column messages-container" [class.unread-message]="unreadMessages">
      <mat-icon (click)="navigateToMessages()" class="pointer">mail_outline</mat-icon>
      <div *ngIf="!utilService.isMobile" class="profile-menu">
        <img class="profile-picture" [src]="imgSrc" (click)="navigateToProfile()" />
      </div>
    </div>
  </div>
  <div>
    <ng-content></ng-content>
  </div>
  <div class="profile"></div>
</div>
