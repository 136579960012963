import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { QueryParamsSearch } from '@needit-models';
import { LoadingIndicationService, ProductsService, SharedService } from '@needit-services';
import { debounceTime, filter } from 'rxjs';
import { UtilsService } from 'honeyfield-component-library';

@Component({
  selector: 'ned-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
})
export class MainLayoutComponent implements OnInit {
  @Output() searchTermChanged: EventEmitter<string> = new EventEmitter<string>();
  public isLoading = LoadingIndicationService.isLoading.pipe(debounceTime(1));
  public showFilterComponent = false;
  public queryParams: QueryParamsSearch;
  public showHeader = true;
  public hideFooter = false;
  public showFilter = true;

  constructor(
    private sharedService: SharedService,
    private router: Router,
    private route: ActivatedRoute,
    public utilService: UtilsService,
    public productsService: ProductsService
  ) {
    LoadingIndicationService.isLoading.next(false);
  }

  ngOnInit(): void {
    this.showFilterComponent = this.checkIfRouteIsProduct(this.router.url);
    this.headerCheck();
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((route: NavigationEnd) => {
      this.showFilterComponent = this.checkIfRouteIsProduct(route.urlAfterRedirects);
      this.headerCheck();
    });
  }

  search(search: string) {
    const params = this.route.snapshot.queryParams;
    const _queryParams = params.name
      ? { name: search?.length ? search : null }
      : { product: search?.length ? search : null };
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: _queryParams,
      queryParamsHandling: 'merge',
    });
  }

  performSearch(search: QueryParamsSearch) {
    this.sharedService.emitSearchTermChanged(search);
  }

  checkIfRouteIsProduct(route = '') {
    if (!route) {
      route = this.router.url;
    }
    return route === '/products' || route.includes('/products?');
  }

  headerCheck() {
    if (this.showFilterComponent && this.utilService.isMobile) {
      this.showHeader = true;
    } else if (!this.showFilterComponent && this.utilService.isMobile) {
      this.showHeader = false;
    }
    this.hideFooter = this.router.url.includes('profile');
    this.filterCheck();
  }

  filterCheck() {
    const params = this.route.snapshot.queryParams;
    this.showFilter = this.router.url.includes('products?') &&
    (Object.keys(params).length > 0 && params.lng !== undefined && params.lat !== undefined) ||
    params.product !== undefined ||
    params.name !== undefined;
  }

  get isMobile () {
    return this.utilService.isMobile;
  }

  get hideMobileFilter() {
    return this.isMobile && !this.showFilter;
  }
}
