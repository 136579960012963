import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';

@Component({
  selector: 'ned-mobile-filter',
  templateUrl: './mobile-filter.component.html',
  styleUrls: ['./mobile-filter.component.scss'],
})
export class MobileFilterComponent implements OnInit {
  public filterForm = this.fb.group({
    rating: [{ value: false, disabled: false }],
  });

  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit(): void {}

  checked() {
    console.log('box checked');
  }
}
