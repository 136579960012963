import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  UntypedFormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
  Validators,
} from '@angular/forms';
import { Subscription, takeUntil } from 'rxjs';
import { HFSelectItem } from '../../interfaces';

@Component({
  selector: 'hf-date-time-picker',
  templateUrl: './date-time-picker.component.html',
  styleUrls: ['./date-time-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => DateTimePickerComponent),
    },
  ],
})
export class DateTimePickerComponent implements OnInit, OnDestroy, ControlValueAccessor, OnChanges {
  @Input() datePickerLabel: string;
  @Input() timePickerLabel: string;
  @Input() error: string;
  @Input() required: boolean;
  @Input() readonly: boolean;
  @Input() disabledSelection = false;
  @Input() placeholder: string;
  @Input() minDate: Date;
  @Input() maxDate: Date;
  @Input() timePickerValues: HFSelectItem[];
  @Input() compareEndTime = false;
  @Input() reservedDates: string[] = [];

  @Output() dateTimeChanged: EventEmitter<Date> = new EventEmitter<Date>();

  disabled: boolean;

  public datePickerInput = new UntypedFormControl();
  public timePickerInput = new UntypedFormControl({ value: '09:00', disabled: false });
  public currentDate: Date;

  private subscriptions = new Subscription();

  constructor() {}

  onChange = (date: Date) => {};
  onTouched = () => {};

  ngOnInit(): void {
    if (this.timePickerValues == null) {
      this.timePickerValues = [
        { label: '00:00', value: '00:00' },
        { label: '00:30', value: '00:30' },
        { label: '01:00', value: '01:00' },
        { label: '01:30', value: '01:30' },
        { label: '02:00', value: '02:00' },
        { label: '02:30', value: '02:30' },
        { label: '03:00', value: '03:00' },
        { label: '03:30', value: '03:30' },
        { label: '04:00', value: '04:00' },
        { label: '04:30', value: '04:30' },
        { label: '05:00', value: '05:00' },
        { label: '05:30', value: '05:30' },
        { label: '06:00', value: '06:00' },
        { label: '06:30', value: '06:30' },
        { label: '07:00', value: '07:00' },
        { label: '07:30', value: '07:30' },
        { label: '08:00', value: '08:00' },
        { label: '08:30', value: '08:30' },
        { label: '09:00', value: '09:00' },
        { label: '09:30', value: '09:30' },
        { label: '10:00', value: '10:00' },
        { label: '10:30', value: '10:30' },
        { label: '11:00', value: '11:00' },
        { label: '11:30', value: '11:30' },
        { label: '12:00', value: '12:00' },
        { label: '12:30', value: '12:30' },
        { label: '13:00', value: '13:00' },
        { label: '13:30', value: '13:30' },
        { label: '14:00', value: '14:00' },
        { label: '14:30', value: '14:30' },
        { label: '15:00', value: '15:00' },
        { label: '15:30', value: '15:30' },
        { label: '16:00', value: '16:00' },
        { label: '16:30', value: '16:30' },
        { label: '17:00', value: '17:00' },
        { label: '17:30', value: '17:30' },
        { label: '18:00', value: '18:00' },
        { label: '18:30', value: '18:30' },
        { label: '19:00', value: '19:00' },
        { label: '19:30', value: '19:30' },
        { label: '20:00', value: '20:00' },
        { label: '20:30', value: '20:30' },
        { label: '21:00', value: '21:00' },
        { label: '21:30', value: '21:30' },
        { label: '22:00', value: '22:00' },
        { label: '22:30', value: '22:30' },
        { label: '23:00', value: '23:00' },
        { label: '23:30', value: '23:30' },
      ];
    }
    if (this.placeholder === '') {
      this.placeholder = 'mm / dd / yy';
    }
    this.subscriptions.add(
      this.datePickerInput.valueChanges.subscribe((dateVal) => {
        const date = new Date(dateVal);
        // emit on change only if new value is different than current
        if (this.currentDate?.getTime() !== date.getTime()) {
          let newDate;
          // don't combine if date already contains time value
          if (date.getHours() > 0 || date.getMinutes() > 0) {
            newDate = this.combineDateAndTime(date);
          } else {
            newDate = this.combineDateAndTime(date, this.timePickerInput.value);
          }
          this.currentDate = newDate;
          // check if current time value is different from new one
          if (
            newDate.getHours() !== +this.getHoursFromDate(this.timePickerInput.value) ||
            newDate.getMinutes() !== +this.getMinutesFromDate(this.timePickerInput.value)
          ) {
            const hours = newDate.getHours() > 9 ? newDate.getHours() : '0' + newDate.getHours();
            const minutes = newDate.getMinutes() > 9 ? newDate.getMinutes() : '0' + newDate.getMinutes();
            this.timePickerInput.setValue(`${hours}:${minutes}`);
          }
          this.onChange(newDate);
        }
      })
    );
    this.subscriptions.add(
      this.timePickerInput.valueChanges.subscribe((timeVal) => {
        const newDate = this.combineDateAndTime(this.datePickerInput.value, timeVal);
        if (this.currentDate.getTime() !== newDate.getTime()) {
          this.currentDate = newDate;
          this.onChange(newDate);
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.compareEndTime && this.timePickerValues) {
      if (changes && changes.minDate) {
        const date = changes.minDate.currentValue;
        this.timePickerValues.forEach((time) => {
          time.disabled = this.isTimeDisabled(date, time);
        });
      }
    }
  }

  isTimeDisabled(date, time) {
    return new Date(date) >= new Date(this.getComparedTime(time));
  }

  getComparedTime(time) {
    const splitedTime = time.value.toString().split(':').map(Number);
    const date = new Date(this.datePickerInput.value);
    date.setHours(splitedTime[0], splitedTime[1], 0);
    return date;
  }

  writeValue(input: string): void {
    this.datePickerInput.setValue(input);
    if (!input) {
      this.timePickerInput.setValue(null);
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
    if (isDisabled) {
      this.datePickerInput.disable();
      this.timePickerInput.disable();
    } else {
      this.datePickerInput.enable();
      this.timePickerInput.enable();
    }
  }

  combineDateAndTime(date: Date, time?: string, includeSeconds = false): Date {
    if (time && time.length) {
      const origStr = time;
      let n = origStr.search(':');
      const hrPart = origStr.substring(0, n);

      let str = origStr.substring(n + 1, origStr.length);
      n = str.search(':');
      const minPart = str.substring(0, 2);

      let secPart = '0';
      if (includeSeconds) {
        str = str.substring(n + 1, str.length);
        n = str.search(':');
        secPart = str.substring(0, 2);
      }

      if (!date) {
        date = new Date();
      }
      date = new Date(this.datePickerInput.value);
      date.setHours(+hrPart, +minPart, +secPart);
      return date;
    }
    return new Date(date);
  }

  getHoursFromDate(time: string) {
    const origStr = time;
    const n = origStr.search(':');
    return origStr.substring(0, n);
  }

  getMinutesFromDate(time: string) {
    const origStr = time;
    let n = origStr.search(':');
    const str = origStr.substring(n + 1, origStr.length);
    n = str.search(':');
    return str.substring(0, 2);
  }
}
