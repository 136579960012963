<div class="wrapper">
  <div class="header">
    <h3>{{ 'UPLOAD_IMAGE.TITLE' | translate }}</h3>
    <mat-icon class="custom-icon pointer" (click)="close()">close</mat-icon>
  </div>
  <div class="content background-white" *ngIf="!img?.url">
    <div class="row">
      <mat-icon>laptop</mat-icon>
      <span (click)="file.click()">{{ 'UPLOAD_IMAGE.BROWSE_FROM_YOUR_PC' | translate }}</span>
      <input hidden type="file" accept="image/png,  image/jpeg" (change)="uploadImage($event.target.files, false)" #file/>
    </div>
    <div class="spacer">
      <div class="line"></div>
      <span>{{ 'GENERAL.OR' | translate }}</span>
      <div class="line"></div>
    </div>
    <div class="row" (drop)="onDrop($event)" (dragover)="onDragOver($event)">
      <mat-icon>back_hand</mat-icon>
      <span>{{ 'UPLOAD_IMAGE.DRAG_AND_DROP' | translate }}</span>
    </div>
  </div>
  <div class="content" *ngIf="img?.url">
    <div class="image-container">
      <img class="image" [src]="img.url" *ngIf="img?.url" [alt]="'PROFILE.USER_IMAGE' | translate"/>
    </div>
    <div class="actions">
      <input hidden type="file" accept="image/png,  image/jpeg" (change)="uploadImage($event.target.files, false)"
             #file/>
      <hf-text-button [width]="'270px'" [text]="'UPLOAD_IMAGE.CHOOSE_ANOTHER' | translate" hfColor="primary"
                      styleType="secondary" (click)="file.click()"></hf-text-button>
      <hf-text-button [width]="'270px'" hfColor="primary" styleType="primary"
                      [text]="'GENERAL.SAVE' | translate" (click)="close()"></hf-text-button>
      <hf-text-button [width]="'270px'" [text]="'GENERAL.CANCEL' | translate" hfColor="primary" styleType="ghost"
                      (click)="close()"></hf-text-button>
    </div>
  </div>
  <ng-container *ngIf="uploadProgress && uploadProgress > 0">
    <div class="upload-overlay">
      <div class="modal">
        <span class="text-indicator">{{ 'GENERAL.UPLOADING_IMAGE' | translate }}...</span>
        <div class="upload-indicator">
          <span [style.width.%]="uploadProgress">{{ uploadProgress }}%</span>
        </div>
      </div>
    </div>
  </ng-container>
</div>
