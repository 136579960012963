<div class="container">
  <form [formGroup]="filterForm">
    <ng-container *ngFor="let filter of filters; let i = index">
      <div class="container__pill-filter">
        <div class="title" (click)="toggleFilter(filter)">
          <i *ngIf="filter.icon" class="icon-{{ filter.icon }}"></i>
          <p class="container__custom-paragraph">
            <ng-container *ngIf="i === 0">
              {{ selectedLocation ? selectedLocation.shortAddress : (filter.name | translate) }}
            </ng-container>
            <ng-container *ngIf="i === 1">
              {{
              selectedRange
                ? (selectedRange.from | date: 'MMMM d':'':dateLocale) + ' - ' + (selectedRange.to | date: 'MMMM d y':'':dateLocale)
                : (filter.name | translate)
              }}
            </ng-container>
            <ng-container *ngIf="i === 2">
              {{ (selectedSubcategoryText ? 'CATEGORIES.' + selectedSubcategoryText : filter.name) | translate }}
            </ng-container>
          </p>
        </div>
        <div class="expander" *ngIf="selectedFilter?.position === filter.position">
          <div class="expander__arrow"></div>
          <div class="expander__navigation" *ngIf="isMobile">
            <span class="filter-name" (click)="toggleFilter(null)">
              <mat-icon [inline]="true">keyboard_arrow_left</mat-icon>
              <span>{{ selectedFilter?.name | translate }}</span>
            </span>
            <span class="clear-btn" (click)="onFilterClear()">
                  {{ 'GENERAL.CLEAR' | translate }}
                </span>
          </div>
          <div class="expander__content" [class.expander__picker-content]="selectedFilter?.position === Filter.Date">
            <ng-container *ngIf="selectedFilter?.position === Filter.Location">
              <div class="search-input">
                <input type="text" matInput [matAutocomplete]="place"
                       placeholder="{{ 'GENERAL.SEARCH_LOCATION' | translate }}"
                       formControlName="location" />
                <mat-icon>search</mat-icon>
                <mat-spinner *ngIf="searching" diameter="20"></mat-spinner>
              </div>
              <mat-autocomplete #place="matAutocomplete" [displayWith]="displayPlaceFn"
                                (optionSelected)="selectPlace($event.option.value)"></mat-autocomplete>
              <ng-container *ngIf="!selectedLocation || (filteredPlaces | async)?.length > 0">
                <div class="locations" *ngFor="let place of filteredPlaces | async">
                  <div class="location" (click)="selectPlace(place)">
                    <div class="location-toggle">
                      <div class="toggler">
                        <span *ngIf="selectedLocation === place.shortAddress"></span>
                      </div>
                    </div>
                    <span>{{ place.shortAddress }}</span>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="selectedLocation">
                <div class="locations">
                  <div class="location" (click)="selectPlace(selectedLocation)">
                    <div class="location-toggle">
                      <div class="toggler">
                        <span></span>
                      </div>
                    </div>
                    <span class="active">{{ selectedLocation.shortAddress }}</span>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="recentSearches.length">
                <div class="recent-title">{{ 'GENERAL.RECENT_SEARCHES' | translate }}</div>
                <div class="recent-search" *ngFor="let rc of recentSearches">
                  <span class="location-box">
                    <img src="assets/icons/location.svg" />
                  </span>
                  <span (click)="selectPlace(rc)">{{ rc.shortAddress }}</span>
                </div>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="selectedFilter?.position === Filter.Date">
              <mat-calendar
                [selected]="filterRange"
                (selectedChange)="selectedChange($event)"
                [minDate]="today"
              ></mat-calendar>
            </ng-container>
            <ng-container *ngIf="selectedFilter?.position === Filter.Category">
              <ned-category-groups
                (closeCategoryGroups)="closeCategories()"
                (categoryChanged)="onCategoryChanged($event)"
                [categories]="rentableCategories"
              ></ned-category-groups>
            </ng-container>
          </div>
          <div class="expander__footer" *ngIf="isMobile">
            <hf-text-button
              [width]="'100% !important'"
              hfColor="primary"
              styleType="primary"
              [text]="translate.get('GENERAL.APPLY') | async"
              (click)="onFilterApply()"
            ></hf-text-button>
          </div>
        </div>
      </div>
    </ng-container>
  </form>
  <ng-container *ngIf="isMobile; else btnClear">
    <mat-icon class="clear" (click)="clearFilters()">clear</mat-icon>
  </ng-container>
  <ng-template #btnClear>
    <hf-text-button [text]="translate.get('GENERAL.CLEAR_FILTERS') | async" hfColor="primary" styleType="ghost"
                    (click)="clearFilters()"></hf-text-button>
  </ng-template>
</div>
<div *ngIf="showBreakLine" class="line-break"></div>
