import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import moment from 'moment';

@Component({
  selector: 'hf-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => DatePickerComponent)
    }
  ]
})
export class DatePickerComponent implements OnInit, ControlValueAccessor {

  @Input() datePickerLabel: string;
  @Input() error: string;
  @Input() required: boolean;
  @Input() readonly: boolean;
  @Input() placeholder: string;
  @Input() minDate: Date;
  @Input() maxDate: Date;
  @Input() reservedDates: string[] = [];
  @Input() disabledSelection = false;
  

  disabled: boolean;

  public datePickerInput = new UntypedFormControl();

  private onChange: (date: Date) => void;
  private onTouch: () => void;

  constructor() { }

  ngOnInit(): void {
    if (this.placeholder === '') {
      this.placeholder = 'mm / dd / yy';
    }
  }

  dateFilter = (d: Date | null): boolean => {
    if (this.reservedDates && this.reservedDates.length > 0) {
      const dates = [];
      this.reservedDates.forEach(date => {
        const reservedDate = moment(date).format("DD/MM/YYYY");
        dates.push(reservedDate.valueOf());
      });
      const day = moment(d ?? new Date()).format("DD/MM/YYYY");
      return !dates.includes(day.valueOf());
    }
    return true;
  }

  boundDateFiler = this.dateFilter.bind(this);

  writeValue(input: string): void {
    this.datePickerInput.setValue(input);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean) {
    this.disabled = isDisabled;
    return isDisabled ? this.datePickerInput.disable() : this.datePickerInput.enable();
  }

  changeDate(e): void {
    this.onChange(e.value);
  }

}
