<form class="card-container" [formGroup]="productReservationForm">
  <div class="hor-line"></div>
  <div class="reservation-card" [ngClass]="{ 'w-26': isPriceSchemePerHour }">
    <hf-date-range-picker *ngIf="!isPriceSchemePerHour" [reservedDates]="reservedDates"
                          [range]="this.productReservationForm.get('range')"
                          [pickerLabel]="translate.get('GENERAL.RANGE_DATES') | async"
                          [startPlaceholder]="translate.get('GENERAL.PICKUP') | async"
                          [endPlaceholder]="translate.get('GENERAL.RETURN') | async" [required]="true"
                          [minDate]="today">
    </hf-date-range-picker>
    <ng-container *ngIf="isPriceSchemePerHour">
      <div class="date-time-picker-holder">
        <hf-date-time-picker [minDate]="itemDefaultStartDate" [datePickerLabel]="translate.get('GENERAL.START') | async"
                             [timePickerLabel]="translate.get('GENERAL.START_TIME') | async"
                             [timePickerValues]="startHoursLabels"
                             formControlName="start" [reservedDates]="reservedDates" required="true">
        </hf-date-time-picker>
        <hf-date-time-picker [minDate]="getMinDate(productReservationForm.get('start').value)"
                             [datePickerLabel]="translate.get('GENERAL.END') | async"
                             [timePickerLabel]="translate.get('GENERAL.END_TIME') | async"
                             [timePickerValues]="endHoursLabels"
                             [compareEndTime]="true" formControlName="end" [reservedDates]="reservedDates"
                             required="true">
        </hf-date-time-picker>
      </div>
    </ng-container>
    <label *ngIf="productReservationForm.errors?.invalidDateRange && !productReservationForm.errors?.invalidEndDate"
           class="error">
      {{ 'ERRORS.INVALID_DATE_RANGE' | translate }}
    </label>
    <label *ngIf="productReservationForm.errors?.invalidEndDate" class="error">
      {{ 'ERRORS.INVALID_RETURN_DATE' | translate }}
    </label>
    <label *ngIf="calculationError" class="error">
      <span *ngIf="calculationError.minDuration"
            [innerHTML]="('ERRORS.'+calculationError.error+'_'+rentingUnit) | translate: { d: calculationError.minDuration }"></span>
      <span *ngIf="!calculationError.minDuration">{{('ERRORS.' + calculationError.error) | translate}}</span>
    </label>

    <div class="prices">
      <div class="prices__price-per-unit">
        <p class="prices__price-per-unit-text" *ngIf="isDayPricingUnit">{{ 'GENERAL.PRICE_PER_DAY' | translate }}</p>
        <p class="prices__price-per-unit-text" *ngIf="!isDayPricingUnit">{{ 'GENERAL.PRICE_PER_HOUR' | translate }}</p>
        <p class="prices__price-per-unit-number">{{ price }}€</p>
      </div>
      <div class="card-line"></div>
      <div class="prices__net-price">
        <p class="prices__net-price-text">{{ 'GENERAL.NET_PRICE' | translate }}</p>
        <p class="prices__net-price-number">{{ netPrice }}€</p>
      </div>
      <div class="card-line"></div>
      <div class="prices__vat">
        <p class="prices__vat-text">{{ 'GENERAL.VAT' | translate }}</p>
        <p class="prices__vat-number">{{ vat }}€</p>
      </div>
      <div class="card-line"></div>
      <div class="prices__deposit">
        <p class="prices__deposit-text">{{ 'GENERAL.DEPOSIT' | translate }}</p>
        <p class="prices__deposit-number">{{ deposit }}€</p>
      </div>
      <div class="card-line"></div>
      <div class="prices__total">
        <p class="prices__total-text">{{ 'GENERAL.TOTAL' | translate }}</p>
        <p class="prices__total-number">{{ totalPrice }}€</p>
      </div>
      <div class="card-line"></div>
    </div>
    <hf-text-button
      [disabled]="(!hasRangeSelected && isDayPricingUnit) || hasError || isMyItem || productReservationForm.invalid"
      [width]="'100%'" hfColor="primary" styleType="primary"
      [text]="translate.get('GENERAL.RESERVE_AND_CONTACT') | async"
      [multiLineText]="true"
      [height]="'3.5rem'"
      (click)="sendRequest()"></hf-text-button>
    <span class="no-payment-message">{{'GENERAL.NO_PAY' | translate}}</span>
  </div>
</form>
