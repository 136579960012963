<div class="menu-item item-{{colorScheme}}" [class.disabled]="disabled" *ngIf="!disabled" [class.expanded]="expanded"
               (click)="navigate($event)"
               routerLinkActive="active" [ngClass]="{'increased-padding': increasePadding}">
  <mat-icon [class]="iconLeft" [ngStyle]="{'background-image': 'url(' + iconPath + ')'}">
  </mat-icon>
  <span>{{ item | translate }}</span>
  <mat-icon *ngIf="!counterActive" class="icon-right"
            (click)="rightIconClick()">
    {{ iconRight }}
  </mat-icon>
  <mat-icon class="toggle-icon" *ngIf="subitems?.length">
    <ng-container *ngIf="!expanded">keyboard_arrow_down</ng-container>
    <ng-container *ngIf="expanded">keyboard_arrow_up</ng-container>
  </mat-icon>
  <div class="counter" *ngIf="counterActive && ( item === 'NAV.REQUESTS' || item === 'NAV.PARTNERS' || item === 'NAV.MESSAGES' || 'PROFILE_NAV.MESSAGES') && count > 0">
    {{ count }}
  </div>
</div>

<ng-container *ngIf="subitems?.length && expanded">
  <div class="subitems">
    <ng-container *ngFor="let subitem of subitems">
      <div *ngIf="!subitem.disabled" class="subitem" [class.active]="subitem.selected" (click)="subitemNavigate(subitem)">
        {{ subitem.label | translate }}
        <div class="counter" *ngIf="subitem?.count > 0">
          {{ subitem.count }}
        </div>
        <mat-icon class="lock" *ngIf="subitem.locked">lock</mat-icon>
        <mat-icon class="lock" *ngIf="!subitem.locked && subitem.href?.length">launch</mat-icon>
      </div>
    </ng-container>
  </div>
</ng-container>