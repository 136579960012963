<div class="login-v2-container">
    <!-- <div class="social-media-btn" (click)="submitLoginViaFacebook()"> -->
        <!-- <div class="icon fb"> -->
            <!-- <img -->
                <!-- src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSI1MTJweCIgaWQ9IkxheWVyXzEiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDUxMiA1MTI7IiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCA1MTIgNTEyIiB3aWR0aD0iNTEycHgiIHhtbDpzcGFjZT0icHJlc2VydmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6Y2M9Imh0dHA6Ly9jcmVhdGl2ZWNvbW1vbnMub3JnL25zIyIgeG1sbnM6ZGM9Imh0dHA6Ly9wdXJsLm9yZy9kYy9lbGVtZW50cy8xLjEvIiB4bWxuczppbmtzY2FwZT0iaHR0cDovL3d3dy5pbmtzY2FwZS5vcmcvbmFtZXNwYWNlcy9pbmtzY2FwZSIgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIiB4bWxuczpzb2RpcG9kaT0iaHR0cDovL3NvZGlwb2RpLnNvdXJjZWZvcmdlLm5ldC9EVEQvc29kaXBvZGktMC5kdGQiIHhtbG5zOnN2Zz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxkZWZzIGlkPSJkZWZzMTkiLz48ZyBpZD0iZzMwMjEiLz48ZyBpZD0iTGF5ZXJfMV8xXyIvPjxnIGlkPSJnMjk5MCI+PHJlY3QgaGVpZ2h0PSI1MTIiIGlkPSJyZWN0Mjk4OSIgcng9IjcwIiByeT0iNzAiIHN0eWxlPSJmaWxsOiMzYzU5OWI7ZmlsbC1vcGFjaXR5OjE7c3Ryb2tlOm5vbmUiIHRyYW5zZm9ybT0ic2NhbGUoLTEsLTEpIiB3aWR0aD0iNTEyIiB4PSItNTEyIiB5PSItNTEyIi8+PGcgaWQ9ImcyOTg2IiB0cmFuc2Zvcm09Im1hdHJpeCgxLjI1MTkyMzIsMCwwLDEuMjUxOTIzMiwtMTA5OC41ODA5LC0yOTIuMzQyMzYpIj48cGF0aCBkPSJtIDExNDYuOTUzMywzODguNzQxMDcgaCAtNDQuMjQxNiB2IC0yOS4wMTYwMSBjIDAsLTEwLjg5NjkyIDcuMjIyMSwtMTMuNDM3NCAxMi4zMDg5LC0xMy40Mzc0IDUuMDc1MiwwIDMxLjIyMDgsMCAzMS4yMjA4LDAgdiAtNDcuOTA0NzcgbCAtNDIuOTk3MywtMC4xNjc4MiBjIC00Ny43MzEyLDAgLTU4LjU5MzQsMzUuNzI4OTEgLTU4LjU5MzQsNTguNTkzMzQgdiAzMS45MzI2NiBoIC0yNy42MDQgdiA0OS4zNjMwOSBoIDI3LjYwNCBjIDAsNjMuMzUwMjcgMCwxMzkuNjgwNzcgMCwxMzkuNjgwNzcgaCA1OC4wNjEgYyAwLDAgMCwtNzcuMDgyODEgMCwtMTM5LjY4MDc3IGggMzkuMTc4IGwgNS4wNjM2LC00OS4zNjMwOSB6IiBpZD0icGF0aDI5ODkiIHN0eWxlPSJmaWxsOiNmZmZmZmYiLz48L2c+PC9nPjwvc3ZnPg==" /> -->
        <!-- </div> -->
        <!-- <span>{{ 'LOGIN.FACEBOOK' | translate }}</span> -->
    <!-- </div> -->
<!--    <div class="social-media-btn" (click)="submitLoginViaGoogle()">-->
<!--        <div class="icon">-->
<!--            <img-->
<!--                src="data:image/svg+xml;base64,PHN2ZyBvdmVyZmxvdz0ic2Nyb2xsIiB2aWV3Qm94PSI3LjU5MTgxMDAxOTUxNzU5MyAxMy41ODA0NTExNTcxOTc5NiA0OC41Mzk5MTM2NTU2NTk5MTYgMzYuNDE5NTQ4ODQyODAyMDQ0IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNTAwIiBoZWlnaHQ9IjEzOTQiPjxsaW5lYXJHcmFkaWVudCBpZD0iYSIgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiIHgxPSIxNC4wMDEiIHgyPSIzMiIgeTE9IjMwLjc0NCIgeTI9IjMwLjc0NCI+PHN0b3Agb2Zmc2V0PSIwIiBzdG9wLWNvbG9yPSIjYzhjOGM4Ii8+PHN0b3Agb2Zmc2V0PSIxIiBzdG9wLWNvbG9yPSIjY2RjZGNkIi8+PC9saW5lYXJHcmFkaWVudD48bGluZWFyR3JhZGllbnQgaWQ9ImIiIGdyYWRpZW50VW5pdHM9InVzZXJTcGFjZU9uVXNlIiB4MT0iMjguNjY1IiB4Mj0iNTAiIHkxPSIzNi40NDciIHkyPSIzNi40NDciPjxzdG9wIG9mZnNldD0iMCIgc3RvcC1jb2xvcj0iI2Q5ZDlkOSIvPjxzdG9wIG9mZnNldD0iMSIgc3RvcC1jb2xvcj0iI2UyZTJlMiIvPjwvbGluZWFyR3JhZGllbnQ+PHBhdGggZD0iTTUzIDUwSDExYTMgMyAwIDAgMS0zLTNWMTdhMyAzIDAgMCAxIDMtM2g0MmEzIDMgMCAwIDEgMyAzdjMwYTMgMyAwIDAgMS0zIDN6IiBmaWxsPSIjZWFlYWVhIi8+PHBhdGggZD0iTTE0IDUwaC0zYTMgMyAwIDAgMS0zLTNWMTdhMyAzIDAgMSAxIDYgMHoiIGZpbGw9IiNkNTRjM2YiLz48cGF0aCBkPSJNNTAgNTBoM2EzIDMgMCAwIDAgMy0zVjE3YTMgMyAwIDEgMC02IDB6IiBmaWxsPSIjYjYzNTI0Ii8+PHBhdGggZD0iTTU0LjcxOCAxOS40NmEzIDMgMCAxIDAtMy40MzYtNC45MmwtMTkuMjggMTQuMDM3LS4wMDIuMDAxLS4wMDItLjAwMS0xOS4yOC0xNC4wMzdhMyAzIDAgMCAwLTMuNDM2IDQuOTJsLS4wOC0uMDU5TDMyIDM2TTU0Ljc5OSAxOS40MDFMMzIgMzYiIGZpbGw9IiNkZTUxNDUiLz48cGF0aCBkPSJNNTMgMTRjLS42MzkgMC0xLjIzMi4yLTEuNzE4LjU0bC0xOS4yOCAxNC4wMzctLjAwMi4wMDEtLjAwMi0uMDAxLTE5LjI4LTE0LjAzN0EyLjk4OCAyLjk4OCAwIDAgMCAxMSAxNHoiIGZpbGw9IiNlZmVmZWYiLz48cGF0aCBkPSJNMTQuMDAxIDI0LjE4OEw4Ljk0NSAxOS4xOGwtLjAwMi4wMDRjLjEwNi4xLjIxOS4xOTIuMzM5LjI3NmwtLjA4LS4wNTkgNC44IDMuNDk1LS4wMDEgMS4zMjgiIGZpbGw9IiNjNjQxMzIiLz48cGF0aCBkPSJNMjguNjY4IDM4LjU5MUwxNCA1MGwuMDAxLTI1LjkzNyAxNC42NjQgMTQuNTI0IiBmaWxsPSIjZTNlM2UzIi8+PHBhdGggZD0iTTI4LjY2OCAzOC41OTFMMzIgMzYgMTQuMDAxIDIyLjg5NnYxLjE2N2wxNC42NjQgMTQuNTI0eiIgZmlsbD0idXJsKCNhKSIvPjxwYXRoIGQ9Ik00MC4wNjEgNTBINTBWMjIuODk1TDMyIDM2bC0zLjMzNSAyLjU4N3oiIGZpbGw9InVybCgjYikiLz48L3N2Zz4=" />-->
<!--        </div>-->
<!--        <span>{{ 'LOGIN.GOOGLE' | translate }}</span>-->
<!--    </div>-->
    <div class="spacer">
        <div class="line"></div>
        <span>{{ 'GENERAL.OR' | translate }}</span>
        <div class="line"></div>
    </div>
    <form [formGroup]="loginForm" (ngSubmit)="submit()">
        <hf-input-text [placeholder]="emailPlaceholder | translate" iconAlign="right" icon="email"
            formControlName="email" type="email" email="true"></hf-input-text>
        <div class="error-container">
            <mat-hint *ngIf="getErrors('email')">
                {{ emailError | translate }}
            </mat-hint>
        </div>
        <hf-input-text [placeholder]="passwordPlaceholder | translate" iconAlign="right" icon="lock"
            formControlName="password" type="password"></hf-input-text>
        <div class="error-container">
            <mat-hint *ngIf="getErrors('password')">
                {{ passwordError | translate }}
            </mat-hint>
        </div>
        <br />
        <hf-text-button [text]="translate.get('LOGIN.LOGIN') | async" hfColor="primary" styleType="primary"
            type="submit" width="100%"></hf-text-button>
        <div class="persist">
            <hf-checkbox labelPosition="before" formControlName="persist"
                label="{{ 'LOGIN.STAY_LOGGED_IN' | translate }}">
            </hf-checkbox>
        </div>
        <mat-label (click)="forgotPassword()">
            {{ 'LOGIN.FORGOT_PASSWORD' | translate }}
        </mat-label>
    </form>
</div>
