<div [ngClass]="styleVertical ? 'wrapper wrapper--vertical' : 'wrapper'">
  <div class="banner-line"></div>
  <img class="mt-12 logo" src="./assets/rent2B-full-color.svg" width="100" height="30" />
  <div class="vertical-line"></div>
  <div class="column">
    <span class="message">
      <ng-container *ngIf="!message">
        {{ 'GENERAL.UPLOAD_MORE_THAN_6_PRODUCTS' | translate }}
      </ng-container>
      <ng-container *ngIf="message">
        {{ message | translate }}
        <ng-container *ngIf="message2">
          <span class="bold">{{ message2 | translate }}</span>
        </ng-container>
      </ng-container>
    </span>
    <div class="go-to-soda-btn" (click)="redirectToSRC()">
      <span>{{ 'GENERAL.GO_TO_SODA_RENTAL_CLUB' | translate }}</span>
      <img src="../../../assets/frame.png" />
    </div>
  </div>
</div>
