<div [ngClass]="{'card' : !isFormInModal}" [style.padding]="isFormInModal || removePadding ? '1px 0' : '2rem'"
  [class.full-height]="fullHeight" [class.shadow]="showShadows && !isFormInModal" class="border-{{shadowBorder}}">
  <div class="title-container" [ngClass]="{'title': title?.length}">
    <h4 *ngIf="title?.length">{{title}}</h4>
    <ng-container *ngIf="tooltip?.length">
      <mat-icon mat-raised-button #iconTooltip="matTooltip" [matTooltip]="tooltip" matTooltipPosition="above"
        (click)="iconTooltip.toggle()" [ngClass]="iconClass?.length ? iconClass : 'info-icon'">info</mat-icon>
    </ng-container>
  </div>
  <h5 *ngIf="subtitle?.length" class="subtitle">{{subtitle}}</h5>
  <div class="card__content">
    <ng-content></ng-content>
  </div>
</div>

