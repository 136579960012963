import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import moment from 'moment';

@Component({
  selector: 'hf-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => DateRangePickerComponent),
    },
  ],
})
export class DateRangePickerComponent implements OnInit {
  @Input() pickerLabel: string;
  @Input() startPlaceholder: string;
  @Input() endPlaceholder: string;
  @Input() required: boolean;
  @Input() readonly: boolean;
  @Input() minDate: Date | null = new Date();
  @Input() maxDate: Date | null;
  @Input() reservedDates: string[] = [];
  @Input() opened: boolean;
  @Input() disabled = false;

  @Input() range = new UntypedFormGroup({
    start: new UntypedFormControl(),
    end: new UntypedFormControl(),
  });

  constructor() {}

  ngOnInit(): void {}

  dateFilter = (d: Date | null): boolean => {
    if (this.reservedDates && this.reservedDates.length > 0) {
      const dates = [];
      this.reservedDates.forEach((date) => {
        const reservedDate = moment(date).format('DD/MM/YYYY');
        dates.push(reservedDate.valueOf());
      });
      const day = moment(d ?? new Date()).format('DD/MM/YYYY');
      return !dates.includes(day.valueOf());
    }
    return true;
  };

  boundDateFiler = this.dateFilter.bind(this);

  dateClass = (): string => (this.reservedDates && this.reservedDates.length > 0 ? 'reserved-date' : '');
}
