import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { QueryParamsSearch } from '../models/query-params-search';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  public searchTermChanged: BehaviorSubject<QueryParamsSearch> = new BehaviorSubject<QueryParamsSearch>(null);

  constructor(private http: HttpClient) {}



  public emitSearchTermChanged(value: QueryParamsSearch): void {
    this.searchTermChanged.next(value);
  }

  public getSearchTermChangedAsObservable(): Observable<QueryParamsSearch> {
    return this.searchTermChanged.asObservable();
  }
}
