import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RentableItemCategory, RentableItemSubCategory } from 'honeyfield-shared-library';
import { ProductsService } from '../../services/products.service';

@Component({
  selector: 'ned-category-groups',
  templateUrl: './category-groups.component.html',
  styleUrls: ['./category-groups.component.scss'],
})
export class CategoryGroupsComponent implements OnInit {
  @Input() showCategorySearch = false;
  @Output() closeCategoryGroups: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() categoryChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() categories: RentableItemCategory[];
  public isMobile = window.innerWidth <= 768;
  public selectedFilter = null;
  public selectedCategory = null;
  public selectedSubcategory = null;
  public selectedLocation = null;
  public recentSearches = [];
  public rentableCategories: RentableItemCategory[];
  public rentableSubCategories: RentableItemSubCategory[];
  public searchText: UntypedFormControl = new UntypedFormControl('');

  categoryGroups = [
    {
      name: 'ACTIVITIES',
      categories: [],
      count: 0,
      expanded: true,
    },
    {
      name: 'CAMPING',
      categories: [],
      count: 0,
      expanded: true,
    },
    {
      name: 'TRANSPORT_TOOLS',
      categories: [],
      count: 0,
      expanded: true,
    },
  ];

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isMobile = window.innerWidth <= 768;
  }

  private outsideClick: boolean;

  constructor(private route: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {
    this.loadItemCategories();
    const params = this.route.snapshot.queryParams;
    this.mapFromQuery(params);
  }

  mapFromQuery(params) {
    this.selectedCategory = params?.category;
    this.selectedSubcategory = params?.subCategory;
    if (params.availableFromDate && params.availableToDate) {
      // TODO to make range from params
    }
  }

  toggleCategory(c, sc) {
    if (this.selectedCategory !== c._id) {
      this.selectedCategory = c._id;
    }
    if (this.selectedSubcategory !== sc._id) {
      this.selectedSubcategory = sc._id;
      this.categoryChanged.emit(sc);
    } else {
      this.selectedCategory = null;
      this.selectedSubcategory = null;
      this.categoryChanged.emit(null);
    }
    if (!this.isMobile) {
      this.navigate({ subCategory: this.selectedSubcategory }, true);
    }
  }

  navigate(params, merge?) {
    const queryParams = this.route.snapshot.queryParams;
    if (queryParams.page && merge) {
      params = Object.assign({}, params, { page: 1, itemsPerPage: queryParams.itemsPerPage });
    }
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: params,
      queryParamsHandling: merge ? 'merge' : '',
    });
  }

  loadItemCategories() {
    const categories = this.categories;
    this.rentableCategories = categories;
    categories.forEach((c, i) => {
      if (!this.isMobile) {
        c.showSubCategories = true;
      } else {
        if (i === 0 || c.name === 'CAMPING' || c.name === 'TRANSPORT_TOOLS') {
          c.showSubCategories = true;
        }
      }
      const _count = c.rentable_item_sub_category as any;
      if (c.name !== 'CAMPING' && c.name !== 'TRANSPORT_TOOLS') {
        this.categoryGroups[0].categories.push(c);
        this.categoryGroups[0].count += _count.length;
      } else {
        if (c.name === 'CAMPING') {
          this.categoryGroups[1].categories.push(c);
          this.categoryGroups[1].count += _count.length;
        }
        if (c.name === 'TRANSPORT_TOOLS') {
          this.categoryGroups[2].categories.push(c);
          this.categoryGroups[2].count += _count.length;
        }
      }
    });
  }

  onCloseCategoryGroups() {
    if (!this.isMobile) {
      if(this.outsideClick) {        
        this.closeCategoryGroups.emit(true);
      }
      else {
        this.outsideClick = true;
      }
    }
  }
}
