import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { debounceTime, Subject, takeUntil } from 'rxjs';
import { HFValidationConfig } from '../../../interfaces/hf-validation-config.interface';
import * as Constants from '../../../constants';
import { UtilsService } from '../../../services/utils.service';

@Component({
  selector: 'hf-registration-v2',
  templateUrl: './registration-v2.component.html',
  styleUrls: ['./registration-v2.component.scss'],
})
export class RegistrationV2Component implements OnInit, OnDestroy {
  @Input() validationConfig: HFValidationConfig;
  @Input() showTC = false;
  @Input() recaptchaConfirmation = false;
  @Input() hideRecaptcha = false;
  @Input() showShadows = true;
  @Input() privacyPolicyURL = Constants.privacyPolicyUrl;
  @Input() termsAndConditionsURL = Constants.termsAndConditionsUrl;
  @Output() toggleForm: EventEmitter<any> = new EventEmitter();
  @Output() submitRegister: EventEmitter<any> = new EventEmitter();
  registerForm: UntypedFormGroup;
  firstNameError: string;
  lastNameError: string;
  emailError: string;
  passwordError: string;
  repeatPasswordError: string;
  firstNamePlaceholder = 'GENERAL.FIRSTNAME';
  lastNamePlaceholder = 'GENERAL.LASTNAME';
  emailPlaceholder = 'GENERAL.EMAIL';
  passwordPlaceholder = 'GENERAL.PASSWORD';
  repeatPasswordPlaceholder = 'LOGIN.REPEAT_PASSWORD';
  numPatternCheck: boolean;
  specialCharPattern: boolean;
  uppercaseCharacterPattern: boolean;
  minLengthPattern: boolean;

  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private fb: UntypedFormBuilder, 
    public translate: TranslateService,
    private utilService: UtilsService) {}

  ngOnInit(): void {
    this.registerForm = this.fb.group({
      firstName: new UntypedFormControl('', this.validationConfig?.register?.firstName?.validator),
      lastName: new UntypedFormControl('', this.validationConfig?.register?.lastName?.validator),
      email: new UntypedFormControl('', [Validators.required, this.utilService.emailFormat]),
      password: new UntypedFormControl('', [Validators.required, UtilsService.passwordMatchValidator('repeatPassword', true)]),
      repeatPassword: new UntypedFormControl('', {
        validators: [UtilsService.passwordMatchValidator('password')],
      }),
      acceptTC: new UntypedFormControl(false),
      acceptGDPR: new UntypedFormControl(false),
    });

    this.registerForm
      .get('password')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((val) => {
        this.numPatternCheck = Constants.numberPattern.test(val);
        this.specialCharPattern = Constants.specialCharacterPattern.test(val);
        this.minLengthPattern = val && val.length > 7;
        this.uppercaseCharacterPattern = Constants.uppercaseCharacterPattern.test(val);
      });

    this.onChanges();
  }

  onChanges() {
    this.registerForm.valueChanges.pipe(debounceTime(300), takeUntil(this.destroy$)).subscribe(() => {
      if (this.registerForm.get('firstName').hasError('minlength')) {
        this.firstNameError = this.validationConfig?.register?.firstName?.errorMessages?.minLength;
      } else {
        this.firstNameError = '';
      }

      if (this.registerForm.get('lastName').hasError('minlength')) {
        this.lastNameError = this.validationConfig?.register?.lastName?.errorMessages?.minLength;
      } else {
        this.lastNameError = '';
      }

      if (this.registerForm.get('email').hasError('email')) {
        this.emailError = this.validationConfig?.register?.email?.errorMessages?.invalid ?? 'ERRORS.EMAIL_INVALID';
      } else if(this.registerForm.get('email').hasError('emailFormat')) {
        this.emailError = this.validationConfig?.register?.email?.errorMessages?.invalid ?? 'ERRORS.EMAIL_INVALID';
      } else {
        this.emailError = '';
      }

      if (this.registerForm.get('password').hasError('minlength')) {
        this.passwordError = this.validationConfig?.register?.password?.errorMessages?.minLength;
      } else {
        this.passwordError = '';
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  submit() {
    if (
      this.registerForm.valid &&
      this.numPatternCheck &&
      this.specialCharPattern &&
      this.minLengthPattern &&
      this.uppercaseCharacterPattern
    ) {
      this.firstNameError = '';
      this.lastNameError = '';
      this.emailError = '';
      this.passwordError = '';
      this.repeatPasswordError = '';
      const value = ['register', this.registerForm.value];
      this.submitRegister.emit(value);
    } else {
      if (this.registerForm.get('firstName').hasError('required')) {
        this.firstNameError = this.validationConfig?.register?.firstName.errorMessages.required ?? '';
      } else if (this.registerForm.get('firstName').hasError('minlength')) {
        this.firstNameError = this.validationConfig?.register?.firstName.errorMessages.minLength ?? '';
      } else {
        this.firstNameError = '';
      }

      if (this.registerForm.get('lastName').hasError('required')) {
        this.lastNameError = this.validationConfig?.register?.lastName.errorMessages.required ?? '';
      } else if (this.registerForm.get('lastName').hasError('minlength')) {
        this.lastNameError = this.validationConfig?.register?.lastName.errorMessages.minLength ?? '';
      } else {
        this.lastNameError = '';
      }

      if (this.registerForm.get('email').hasError('required')) {
        this.emailError = this.validationConfig?.register?.email.errorMessages.required ?? '';
      } else if (this.registerForm.get('email').hasError('email')) {
        this.emailError = this.validationConfig?.register?.email.errorMessages.invalid ?? '';
      } else {
        this.emailError = '';
      }

      if (this.registerForm.get('password').hasError('required')) {
        this.passwordError = this.validationConfig?.register?.password.errorMessages.required ?? '';
      } else if (this.registerForm.get('password').hasError('minlength')) {
        this.passwordError = this.validationConfig?.register?.password.errorMessages.minLength ?? '';
      } else {
        this.passwordError = '';
      }

      if (this.registerForm.get('repeatPassword').hasError('required')) {
        this.repeatPasswordError = this.validationConfig?.register?.repeatPassword.errorMessages.required ?? '';
      } else {
        this.repeatPasswordError = '';
      }

      if (this.registerForm.get('repeatPassword').hasError('notSame')) {
        this.repeatPasswordError = this.validationConfig?.register?.repeatPassword.errorMessages.notSame ?? '';
      } else {
        this.repeatPasswordError = '';
      }
    }
  }

  openTC() {
    window.open(this.termsAndConditionsURL, '_blank');
  }

  openGDPR() {
    window.open(this.privacyPolicyURL, '_blank');
  }

  login() {
    this.toggleForm.emit();
  }

  getErrors(error: string) {
    if (error === 'firstName') {
      return (
        (this.firstNameError &&
          (this.registerForm.get('firstName').touched || this.registerForm.get('firstName').dirty)) ||
        (this.firstNameError && this.registerForm.get('firstName').pristine)
      );
    } else if (error === 'lastName') {
      return (
        (this.lastNameError &&
          (this.registerForm.get('lastName').touched || this.registerForm.get('lastName').dirty)) ||
        (this.lastNameError && this.registerForm.get('lastName').pristine)
      );
    } else if (error === 'email') {
      return (
        (this.emailError && (this.registerForm.get('email').touched || this.registerForm.get('email').dirty)) ||
        (this.emailError && this.registerForm.get('email').pristine)
      );
    } else if (error === 'password') {
      return (
        (this.passwordError &&
          (this.registerForm.get('password').touched || this.registerForm.get('password').dirty)) ||
        (this.passwordError && this.registerForm.get('password').pristine)
      );
    } else if (error === 'repeatPassword') {
      return (
        (this.repeatPasswordError &&
          (this.registerForm.get('repeatPassword').touched || this.registerForm.get('repeatPassword').dirty)) ||
        (this.repeatPasswordError && this.registerForm.get('repeatPassword').pristine)
      );
    }
  }
}
