import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent } from 'firebase/analytics';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  public firebaseApp = null;
  public analytics = null;

  constructor() {}

  initializeFirebase() {
    this.firebaseApp = initializeApp(environment.firebaseConfig);
    this.analytics = getAnalytics(this.firebaseApp);
  }

  logNotificationEvent(logType, data) {
    logEvent(this.analytics, logType, { ...data });
  }
}
