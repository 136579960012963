import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { User } from '@shared-library-models/api-interfaces';
import { environment } from '../../environments/environment';
import { Pagination } from '@shared-library-models/api-interfaces';
import { MessageDto } from 'projects/ui-library/src/lib/building-blocks/chat/models/message.dto';

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  constructor(private http: HttpClient) {}

  saveMessage(data: MessageDto): Observable<any> {
    const f = new FormData();
    f.append('file', data.file);
    f.append('receiverId', data.receiver.id);
    f.append('message', data.message);
    f.append('type', data.type.toString());
    if(data.requestId){
      f.append('requestId', data.requestId);
    }


    const url = `${environment.apiEndpointV2}/messages`;
    return this.http.post<any>(url, f);
  }

  getMessagesForUser(receiverId: string): Observable<any[]> {
    const url = `${environment.apiEndpointV2}/messages?receiverId=${receiverId}`;
    return this.http.get<any[]>(url);
  }

  getUnreadMessages(): Observable<number> {
    const url = `${environment.apiEndpointV2}/messages/unread`;
    return this.http.get<number>(url);
  }

  readMessagesBySender(senderId: string) {
    const url = `${environment.apiEndpointV2}/messages/read-messages?senderId=${senderId}`;
    return this.http.post<any>(url, null);
  }
}
