import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  EventEmitter,
  Inject,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
  PLATFORM_ID,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { HFMenuItem, UtilsService } from 'honeyfield-component-library';
import { ToastService } from 'projects/hf-library-demo/src/app/services/toast.service';
import { Observable, Subject, takeUntil } from 'rxjs';
import { MessageService } from '../../../services/message.service';
import { SocketsService } from '../../../services/SocketsService';
import { AuthService } from '../../services';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'ned-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input() isPLPActive = false;
  @Input() activeSearchTerm: string;
  @Output() searchTermChanged: EventEmitter<string> = new EventEmitter<string>();
  public searchText: UntypedFormControl = new UntypedFormControl('');
  public dropdownItems: HFMenuItem[] = [
    {
      label: 'DROPDOWN.OVERVIEW',
      url: '/profile/overview',
    },
    {
      label: 'DROPDOWN.MESSAGES',
      url: '/messages',
    },
    {
      label: 'DROPDOWN.SETTINGS',
      url: '/profile/info',
    },
    {
      label: 'DROPDOWN.WISHLIST',
    },
    {
      label: 'DROPDOWN.RENTING_HISTORY',
    },
    {
      label: 'DROPDOWN.LOGOUT',
      url: '/logout',
    },
  ];
  public imgSrc = 'assets/profile.png';
  public newMessagesSocket: Observable<any>;
  public readMessagesSocket: Observable<any>;

  public unreadMessages = 0;
  public currentUser = AuthService.user;

  private destroy$: Subject<boolean> = new Subject<boolean>();
  private socketsService: SocketsService;

  constructor(
    @Inject(PLATFORM_ID) private platformId,
    public translate: TranslateService,
    private router: Router,
    public utilService: UtilsService,
    private messageService: MessageService,
    private authService: AuthService,
    private toastService: ToastService,
    private injector: Injector,
    private route: ActivatedRoute
  ) {
    this.route.queryParams.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      const txt = params.name || params.product;
      setTimeout(() => {
        if (this.searchText.value !== txt) {
          this.searchText.patchValue(txt ?? '');
        }
      });
    });
  }

  ngOnInit(): void {
    if (AuthService.user?.image?.url) {
      this.imgSrc = AuthService.user?.image?.url;
    }
    if (this.authService.isUserLoggedIn()) {
      this.loadSocketService();
      this.getUnreadMessages();
      this.subscribeToSocketMessages();
    }
  }

  onSearch(value) {
    this.searchTermChanged.emit(value);
  }

  getUnreadMessages() {
    this.messageService
      .getUnreadMessages()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => (this.unreadMessages = data));
  }

  navigateToLandingPage() {
    window.open(environment.needitLandingPageUrl, '_self');
  }

  navigateToProfile() {
    this.router.navigateByUrl('profile/overview');
  }

  navigateToMessages() {
    this.router.navigateByUrl('/profile/messages');
  }

  subscribeToSocketMessages() {
    if (!this.socketsService) return;

    this.newMessagesSocket = this.socketsService.getSocketEventI(`${this.currentUser._id}/messages`);
    this.newMessagesSocket.pipe(takeUntil(this.destroy$)).subscribe((data: { senderName: string }) => {
      if (!this.router.url.includes('messages')) {
        this.getUnreadMessages();
        this.toastService.presentToast(
          this.translate.instant('NOTIFICATIONS.NEW_MESSAGE_TITLE'),
          this.translate.instant('NOTIFICATIONS.NEW_MESSAGE_TEXT', { name: data.senderName }),
          'mark_email_unread',
          'info'
        );
      }
    });

    this.readMessagesSocket = this.socketsService.getSocketEventI(`${this.currentUser._id}/messages-read`);

    this.readMessagesSocket.pipe(takeUntil(this.destroy$)).subscribe((data: { unreadMessages: number }) => {
      this.unreadMessages = data.unreadMessages;
    });
  }

  loadSocketService() {
    if (isPlatformBrowser(this.platformId)) {
      this.socketsService = this.injector.get(SocketsService);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  get isMobile() {
    return this.utilService.isMobile;
  }
}
