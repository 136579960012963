<div class="carousel-container" (click)="close()">
  <div class="carousel-window" (click)="$event.stopPropagation()">
    <div class="carousel-header">
      <span class="carousel-title">{{ 'GENERAL.PRODUCT_IMAGES' | translate }}</span>
      <span class="carousel-img-number">
        <li>{{ selectedImage + 1 }}/{{ productImages.length }}</li>
      </span>
      <span class="close-btn">
        <mat-icon (click)="close()">close</mat-icon>
      </span>
    </div>
    <img [src]="productImages[selectedImage]?.url ?? placeholder" alt="" class="carousel-selected-img" />
    <div class="other-images">
      <ng-container *ngFor="let image of productImages; let i = index">
        <img
          [src]="image?.url ?? placeholder"
          (click)="selectedImage = i"
          [ngStyle]="{ border: selectedImage === i ? borderStyle : 'none' }"
          alt=""
          class="other-image"
        />
      </ng-container>
    </div>
  </div>
  <div class="carousel-btn" (click)="$event.stopPropagation()">
    <span class="btn">
      <mat-icon [inline]="true" (click)="previous()">keyboard_arrow_left</mat-icon>
    </span>
    <span></span>
    <span class="btn">
      <mat-icon [inline]="true" (click)="next()">keyboard_arrow_right</mat-icon>
    </span>
  </div>
</div>
