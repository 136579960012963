import {
  ActionReducerMap,
  MetaReducer
} from '@ngrx/store';
import * as fromUser from './user.reducer';
import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import { environment } from '../../../environments/environment';


export interface State {
  router: RouterReducerState;
  [fromUser.userFeatureKey]: fromUser.UserState;
}

export const reducers: ActionReducerMap<State> = {
  router: routerReducer,
  user: fromUser.reducer,
};


export const metaReducers: MetaReducer<State>[] = !environment.production ? [] : [];
