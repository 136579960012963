import { NgModule } from '@angular/core';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatInputModule } from '@angular/material/input';
import {
  EmptySearchComponent,
  FilterComponent,
  ImagesCarouselComponent,
  ImagesSliderComponent,
  ProductReservationComponent,
  ProductTileComponent,
  UploadImageComponent,
} from '@needit-shared';
import { CommonModule } from '@angular/common';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  CardModule,
  DatePickerModule,
  DateRangePickerModule,
  DateTimePickerModule,
  GenericTableModule,
  IconButtonModule,
  SearchModule,
  TextButtonModule,
  TextIconButtonModule,
} from 'honeyfield-component-library';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatListModule } from '@angular/material/list';
import { UserReviewComponent } from './user-review/user-review.component';
import { ProductsFilterComponent } from './products-filter/products-filter.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { RequestService } from 'honeyfield-shared-library';
import { GoToSodaBannerComponent } from './go-to-soda-banner/go-to-soda-banner.component';
import { MomentPipe } from './pipes/moment.pipe';
import { EllipsisPipe } from './pipes/ellipsis.pipe';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatCardModule } from '@angular/material/card';
import { CategoryGroupsComponent } from './category-groups/category-groups.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ClickOutsideModule } from 'ng-click-outside';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';

const components = [
  FilterComponent,
  ImagesCarouselComponent,
  ProductReservationComponent,
  ProductTileComponent,
  UploadImageComponent,
  ImagesSliderComponent,
  EmptySearchComponent,
  UserReviewComponent,
  GoToSodaBannerComponent,
  ProductsFilterComponent,
  CategoryGroupsComponent,
];
const matImports = [
  MatDialogModule,
  MatFormFieldModule,
  MatInputModule,
  MatIconModule,
  MatProgressBarModule,
  MatListModule,
  MatAutocompleteModule,
  MatTabsModule,
  MatTooltipModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatMomentDateModule,
  MatCardModule,
  MatProgressSpinnerModule,
];
const honeyfieldModules = [
  TextButtonModule,
  IconButtonModule,
  DatePickerModule,
  DateRangePickerModule,
  TextIconButtonModule,
  CardModule,
  GenericTableModule,
  SearchModule,
  DateTimePickerModule,
];

export const httpLoaderFactory = (http: HttpClient) =>
  new TranslateHttpLoader(http, './assets/i18n/', '.json?v=' + new Date().getTime());

@NgModule({
  declarations: [...components, EllipsisPipe, MomentPipe],
  imports: [
    CommonModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient],
      },
      defaultLanguage: 'en',
    }),
    ReactiveFormsModule,
    FormsModule,
    ClickOutsideModule,
    ...honeyfieldModules,
    ...matImports,
  ],
  exports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    FormsModule,
    ClickOutsideModule,
    MomentPipe,
    ...components,
    ...matImports,
    ...honeyfieldModules,
  ],
  providers: [RequestService, EllipsisPipe],
})
export class SharedModule {
  constructor(translate: TranslateService) {
    const currentLanguage = translate.getBrowserLang();
    translate.setDefaultLang(currentLanguage);
    translate.use(currentLanguage);
  }
}
