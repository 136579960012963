import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AuthGuard } from './guards';
import { AuthInterceptor } from './interceptors';
import {
  AuthLayoutComponent,
  FooterComponent,
  HeaderComponent,
  MainLayoutComponent,
  MobileMenuComponent,
} from './layouts';
import { DropdownMenuModule, SearchModule, TextButtonModule } from 'honeyfield-component-library';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatInputModule } from '@angular/material/input';
import { JoinNowFooterComponent } from './layouts/join-now-footer/join-now-footer.component';
import { CheckboxModule, InputTextModule } from 'honeyfield-component-library';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MobileFilterComponent } from './layouts/mobile-filter/mobile-filter.component';
import { SharedModule } from '../shared/shared.module';

const layoutComponents = [
  AuthLayoutComponent,
  FooterComponent,
  HeaderComponent,
  MainLayoutComponent,
  MobileMenuComponent,
  JoinNowFooterComponent,
  MobileFilterComponent,
];
const matImports = [
  MatFormFieldModule,
  MatInputModule,
  MatIconModule,
  MatProgressBarModule,
  MatCheckboxModule,
  MatExpansionModule,
  CheckboxModule,
  InputTextModule,
];
@NgModule({
  declarations: [...layoutComponents],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    SearchModule,
    SharedModule,
    ...matImports,
    TextButtonModule,
    DropdownMenuModule,
  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }, AuthGuard],
  exports: [],
})
export class CoreModule {}
